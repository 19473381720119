import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';

import type { WifiQuery } from '../generated/WifiQuery';
import { generateWifiQRCode } from '../utils/wifiQrCodeGenerator';
import { useStore } from './useStore';

const WIFI_QUERY = gql`
  query WifiQuery {
    wifiSettings {
      SSID
      password
    }
  }
`;

export function useWifiCredentials() {
  const { data, loading, error } = useQuery<WifiQuery>(WIFI_QUERY);
  const setWifiSettings = useStore((state) => state.setWifiSettings);

  useEffect(() => {
    if (!loading && !error && data) {
      if (data?.wifiSettings?.password && data.wifiSettings.SSID) {
        generateWifiQRCode({
          ssid: data?.wifiSettings?.SSID,
          password: data?.wifiSettings?.password,
        }).then((qrCode) => setWifiSettings(data.wifiSettings, qrCode));
      }
    }
  }, [data, loading, error, setWifiSettings]);
}
