import './room-page.css';

import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { getSlotCardData } from '../../bookingController';
import { RoomHeader } from '../../Components/Header/RoomHeader';
import { SlotOptionModalFlow } from '../../Components/OptionButton';
import { SlotCard } from '../../Components/SlotCard';
import { WifiModal } from '../../Components/WifiModal';
import { useStore } from '../../hooks/useStore';

type Props = {
  onBackToFloorPage: () => void;
  roomName: string;
};

export interface Slot {
  duration: number;
  endTime: number;
  id: string;
  optionButtonHandler?: () => void;
  organizer?: string;
  room: string;
  startTime: number;
  title?: string;
}

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledSlotCardsContainer = styled.div`
  animation: ${fadeInAnimation} 300ms ease-out;
`;

export function RoomPage({ roomName, onBackToFloorPage }: Props) {
  const update = useStore((state) => state.update);
  const [slots, setSlots] = useState<Slot[]>([]);
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenWifiModal, setIsOpenWifiModal] = useState<boolean>(false);
  const [optionModalMeetingId, setOptionModalMeetingId] = useState<
    string | undefined
  >(undefined);

  const [slotCardEditingMeetingId, setSlotCardEditingMeetingId] = useState<
    string | undefined
  >(undefined);

  const isRoomOccupiedNow = slots.some(
    (slot) =>
      slot.startTime < currentTime && slot.endTime > currentTime && slot.title,
  );

  useEffect(() => {
    const handleStorage = () => {
      getSlotCardData(roomName).then((slots) => {
        setSlots(slots);
      });
    };

    getSlotCardData(roomName).then((slots) => {
      setSlots(slots);
      window.addEventListener('storage', handleStorage);
    });

    return () => {
      window.removeEventListener('storage', handleStorage);
    };
  }, [roomName, update]);

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date().getTime()), 1000);

    return function cleanup() {
      clearInterval(timer);
    };
  });
  const optionButtonHandler = (meetingId: string) => {
    setIsOpenModal(true);
    setOptionModalMeetingId(meetingId);
    getSlotCardData(roomName).then((slots) => setSlots(slots));
  };

  const handleWifiModalOpen = () => {
    setIsOpenWifiModal(true);
  };

  const handleWifiModalClose = () => {
    setIsOpenWifiModal(false);
  };

  return (
    <section>
      <RoomHeader
        isRoomOccupiedNow={isRoomOccupiedNow}
        roomName={roomName}
        onClick={onBackToFloorPage}
        onWifiClick={handleWifiModalOpen}
      />
      <StyledSlotCardsContainer className="list">
        {slots.map(
          (slot) =>
            slot.endTime > currentTime && (
              <SlotCard
                {...slot}
                key={slot.id}
                id={slot.id}
                optionButtonHandler={() => optionButtonHandler(slot.id)}
                editingMode={slot.id === slotCardEditingMeetingId}
                onCancelButton={() => setSlotCardEditingMeetingId(undefined)}
                slotOptionId={slotCardEditingMeetingId}
              />
            ),
        )}
      </StyledSlotCardsContainer>
      <SlotOptionModalFlow
        isOpen={isOpenModal}
        meetingId={optionModalMeetingId}
        onCloseModal={() => setIsOpenModal(false)}
        onEditModal={() => setSlotCardEditingMeetingId(optionModalMeetingId)}
      />
      <WifiModal isOpen={isOpenWifiModal} onCloseModal={handleWifiModalClose} />
    </section>
  );
}

export default RoomPage;
