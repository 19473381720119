import { gql } from '@apollo/client';

// The approval of the signage is done by the admin
// The admin-panel can be found at the current server url + /admin-panel
export const REQUEST_SIGNAGE_APPROVAL = gql`
  mutation RequestSignageApproval($info: String!, $name: String!) {
    requestSignageApproval(info: { info: $info }, name: $name)
  }
`;

// Public name is the name of the current Organization
// settings can be found within the admin-panel
export const PUBLIC_NAME = gql`
  query PublicName {
    publicSettings {
      name
    }
  }
`;
