import styled from 'styled-components';

import { color } from '../../styles';

export const Login = styled.div`
  display: inline-block;
  background: ${color.niceBlue};
  color: ${color.white};
  border: none;
  border-radius: 3px;
  padding: 0.7em 0.8em;
  font-size: 16px;
`;
export const DevModeToggle = styled.div`
  background: ${color.mediumGrey};
  width: 6rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: ${color.white};
  border: none;
  border-radius: 3px;
  padding: 0.7em 0.8em;
  font-size: 16px;
  margin-top: 1rem;
`;
export const Input = styled.input<{ error: boolean }>`
  border: 2px solid transparent;
  border-bottom-color: ${(p) => (p.error ? color.busy : color.white)};
  background: none;
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin: auto auto 5em auto;
  color: ${color.white};
  padding: 1em 0.5em;
  border-radius: 0;
  user-select: auto;

  &:focus {
    outline: none;
  }
`;
