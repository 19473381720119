import styled, { css } from 'styled-components';

export const StyledButton = styled.button<{ variant: string }>`
  width: 50px;
  height: 50px;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  position: fixed;
  z-index: 10;

  ${(p) => {
    switch (p.variant) {
      case 'left':
        return css`
          left: 0;
          top: 50%;
          transform: translateY(-50%) rotate(180deg);
        `;
      case 'right':
        return css`
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        `;
      case 'up':
        return css`
          top: 0;
          left: 50%;
          transform: translateX(-50%) translateY(90px) rotate(270deg);
        `;
      case 'down':
        return css`
          bottom: 0;
          left: 50%;
          transform: translateX(-50%) rotate(90deg);
        `;
      default:
        return '';
    }
  }}
`;
