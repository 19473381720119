import { useEffect, useState } from 'react';

import useReloadApp from '../../hooks/useReloadApp';

const Clock = () => {
  const [date, setDate] = useState(new Date());
  const reloadApp = useReloadApp();

  const reloadTime = process.env.REACT_APP_RELOAD_TIME ?? '01:00';

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      setDate(now);

      const currentTime = now.toLocaleTimeString('de', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
      const currentMinute = now.toISOString().slice(0, 16);

      if (currentTime === reloadTime) {
        const lastReloadMinute = localStorage.getItem('lastReloadMinute');

        if (lastReloadMinute !== currentMinute) {
          reloadApp();
          localStorage.setItem('lastReloadMinute', currentMinute);
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [reloadApp, reloadTime]);

  return (
    <time className="time title-2">
      {date.toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit' })}
    </time>
  );
};

export default Clock;
