import styled, { keyframes } from 'styled-components';

import { color } from '../../styles';

const pulse = keyframes`
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
`;
export const StyledDot = styled.div`
  margin-left: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${color.red};
  animation: ${pulse} 2.5s infinite;
`;
