import styled from 'styled-components';

import { color } from '../../styles';

export const StyledContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;
export const StyledLabel = styled.label`
  color: ${color.blue2925C};

  &.duration-text-opacity {
    opacity: 20%;
  }

  &.mail {
    color: #bdbdbd;
  }
`;
