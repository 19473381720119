import '../text-styles.css';

import React from 'react';

import optionsButton from '../../img/Options Icon.svg';

type OptionButtonProps = {
  onClickHandler?: () => void;
};
const OptionButton = ({ onClickHandler }: OptionButtonProps) => {
  return (
    <div>
      <img src={optionsButton} alt="Option Button" onClick={onClickHandler} />
    </div>
  );
};
export default OptionButton;
