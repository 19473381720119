import styled, { keyframes } from 'styled-components';

const len = 242.776657104492;

const anim = keyframes`
  12.5% {
    stroke-dasharray: ${len * 0.14}px, ${len}px;
    stroke-dashoffset: -${len * 0.11}px;
  }
  43.75% {
    stroke-dasharray: ${len * 0.35}px, ${len}px;
    stroke-dashoffset: -${len * 0.35}px;
  }
  100% {
    stroke-dasharray: ${len * 0.01}px, ${len}px;
    stroke-dashoffset: -${len * 0.99}px;
  }
`;
export const Container = styled.svg`
  display: block;
  margin: auto;
  height: 6em;
  & #outline {
    stroke-dashoffset: 0;
    stroke-dasharray: ${len * 0.01}px, ${len}px;
    animation: ${anim} 1.6s linear infinite;
  }
`;
