import './floor-page.css';
import '../../Components/text-styles.css';
import '../../Components/FloorNavigation/FloorNavigation';
import 'swiper/css';

import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperClass } from 'swiper/types';

import { Floor } from '../../Components/Floor';
import type { FloorMap } from '../../Components/Floor/Floor';
import { FloorNavigation } from '../../Components/FloorNavigation';
import { FloorNavigationButton } from '../../Components/FloorNavigationButton';
import { FloorHeader } from '../../Components/Header/FloorHeader';
import useOrientation, { Orientation } from '../../hooks/useOrientation';
import { useStore } from '../../hooks/useStore';
import { StyledAnimationWrapper } from './StyledFloorPage';

type Props = {
  availableFloors: number[];
  devicePosition: string | null;
  onSelectFloor: (level: number) => void;
  onSelectRoom: (title: string) => void;
  selectedFloor: number;
};

export const StyledMapWrapper = styled.div<{ variant: Orientation }>`
  display: grid;
  place-items: center;

  ${(p) =>
    p.variant === Orientation.PORTRAIT &&
    css`
      transform: translate(60px, 80px) scale(0.9);
    `}
`;

const StyledSwiper = styled(Swiper)<{ variant: Orientation }>`
  ${(p) =>
    p.variant === Orientation.PORTRAIT &&
    css`
      height: 100vh;
    `}
  z-index: 0;
`;

const StyledSwiperSlide = styled(SwiperSlide)`
  display: grid;
  place-items: center;
`;

const isFloorBoundary = (floor: number, availableFloors: number[]) => ({
  isFirstFloor: floor === availableFloors[0],
  isLastFloor: floor === availableFloors[availableFloors.length - 1],
});

const FloorPage = ({
  availableFloors,
  devicePosition,
  selectedFloor,
  onSelectRoom,
  onSelectFloor,
}: Props) => {
  const floorBoundaries = isFloorBoundary(selectedFloor, availableFloors);
  const floors = useStore((state) => state.floors);

  const { orientation, isPortrait } = useOrientation();

  let floorsWithCurrentOrientation: FloorMap[] = floors.map(
    ({ floorNumber, map }) => ({
      level: floorNumber,
      map:
        orientation === Orientation.LANDSCAPE ? map.horizontal : map.portrait,
    }),
  );

  if (orientation === Orientation.LANDSCAPE) {
    floorsWithCurrentOrientation = floorsWithCurrentOrientation.reverse();
  }

  const handleSlideChange = (swiper: SwiperClass) => {
    onSelectFloor(availableFloors[swiper.activeIndex]);
  };

  return (
    <>
      <FloorHeader />
      <StyledAnimationWrapper className="floor-wrapper">
        <StyledSwiper
          className="mySwiper"
          direction={isPortrait ? 'vertical' : 'horizontal'}
          onSlideChange={handleSlideChange}
          onAfterInit={(swiper: SwiperClass) =>
            swiper.slideTo(availableFloors.indexOf(selectedFloor), 0)
          }
          variant={isPortrait ? Orientation.PORTRAIT : Orientation.LANDSCAPE}
        >
          <FloorNavigationButton
            floorBoundaries={floorBoundaries}
            variant="previous"
          />
          {floorsWithCurrentOrientation.map((floor: FloorMap) => (
            <StyledSwiperSlide key={'swiper-slide' + floor.level}>
              <StyledMapWrapper
                variant={
                  isPortrait ? Orientation.PORTRAIT : Orientation.LANDSCAPE
                }
              >
                <Floor
                  key={floor.level}
                  {...floor}
                  onSelectRoom={onSelectRoom}
                  currentPosition={devicePosition}
                />
              </StyledMapWrapper>
            </StyledSwiperSlide>
          ))}
          <FloorNavigationButton
            floorBoundaries={floorBoundaries}
            variant="next"
          />
          <FloorNavigation
            floors={floorsWithCurrentOrientation}
            selectedLevel={selectedFloor}
          />
        </StyledSwiper>
      </StyledAnimationWrapper>
    </>
  );
};

export default FloorPage;
