import { useStore } from '../../hooks/useStore';
import logo from '../../img/logo.svg';
import { StyledContainer } from './Header.styles';
import { PageHeader } from './PageHeader';

export const FloorHeader = () => {
  const isDevMode = useStore((state) => state.isDevMode);
  const toggleDevMode = useStore((state) => state.toggleDevMode);

  return (
    <PageHeader>
      <StyledContainer>
        <img className="logo" src={logo} alt="logo" />
        {isDevMode && (
          <>
            <span>Dev Mode</span>
            <button onClick={toggleDevMode}>exit</button>
          </>
        )}
      </StyledContainer>
    </PageHeader>
  );
};
