export function createTimePeriodForToday(
  start: string,
  end: string,
): {
  endTime: number;
  startTime: number;
} {
  const startTime = new Date().setHours(
    Number(start.split(':')[0]),
    Number(start.split(':')[1]),
    0,
    0,
  );
  const endTime = new Date().setHours(
    Number(end.split(':')[0]),
    Number(end.split(':')[1]),
    0,
    0,
  );
  return { startTime, endTime };
}

export function getMinutesUntil(
  currentTime: ReturnType<typeof Date.prototype.getTime>,
  futureTime: ReturnType<typeof Date.prototype.getTime>,
): number {
  if (currentTime < futureTime) {
    return Math.floor(futureTime - currentTime) / 60000;
  }
  return 0;
}

/**
 * Rounds a given Unix timestamp to the nearest minute, setting seconds and milliseconds to zero.
 * @param timestamp - The original Unix timestamp in milliseconds.
 * @returns - The rounded Unix timestamp in milliseconds with seconds and milliseconds set to zero.
 */
export function roundUnixTimestampToMinutes(timestamp: number): number {
  const date = new Date(timestamp);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.getTime();
}

/**
 * Adjusts the minutes on a given date by the specified amount.
 * @param timestamp - The original timestamp in milliseconds.
 * @param minutes - The number of minutes to add or subtract.
 * @returns - The adjusted timestamp in milliseconds.
 */
function adjustMinutesOnDate(timestamp: number, minutes: number): number {
  const date = new Date(timestamp);
  date.setMinutes(date.getMinutes() + minutes);
  return date.getTime();
}

/**
 * Adds one minute to the given date.
 * @param timestamp - The original timestamp in milliseconds.
 * @returns - The adjusted timestamp in milliseconds with one minute added.
 */
export function addOneMinuteToDate(timestamp: number): number {
  return adjustMinutesOnDate(timestamp, 1);
}

/**
 * Subtracts one minute from the given date.
 * @param timestamp - The original timestamp in milliseconds.
 * @returns - The adjusted timestamp in milliseconds with one minute subtracted.
 */
export function subtractOneMinuteFromDate(timestamp: number): number {
  return adjustMinutesOnDate(timestamp, -1);
}
