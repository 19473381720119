import moment from 'moment';

import config from '../config';
import type { RoomsQuery_rooms_items } from '../generated/RoomsQuery';
import type Room from './Room';
import type Timer from './Timer';

const { BOOKING_SLOTS } = config;

type EventType = RoomsQuery_rooms_items & {
  type?: 'gap';
};
type Slot = {
  bookable: boolean;
  end: string;
  minutes: string | number;
  start: string;
};
/**
 *
 *
 * @export
 * @class Event
 */
export default class Event {
  // The ID of the event
  id: string | any = null;

  // The type of the event
  type: string;

  // Reference to the apps {Timer}
  timer: Timer;

  // Reference to the parent {Room}
  room: Room;

  // The events title
  summary!: string;

  // The starting time
  start!: moment.Moment;

  // The end time
  end!: moment.Moment;

  // Indicates, whether the client is the owner of the event or not
  own!: boolean;

  // Indicates, whether the client invited to the event or not
  invited!: boolean;

  // Indicates, whether the client is allowed can join the meeting or not
  anyoneCanAddSelf!: boolean;

  // The organizers name or email
  organizer!: string;

  // List of all attendees names or emails
  attendees!: string[];

  /**
   * @description Creates an instance of Event.
   * @param {Room} room Reference to the parent {Room}
   * @param {Object} ev The event data
   */
  constructor(room: Room, ev: EventType) {
    this.room = room;
    this.timer = room.timer;
    this.id = ev.id;
    this.type = ev.type ?? 'event';
    this.updateData(ev);
  }

  /**
   * @description Updates events properties
   * @param  {Object} ev The event data
   */

  updateData = (ev: EventType) => {
    this.summary = ev.summary || 'Meeting';
    this.start = moment(ev.start?.dateTime);
    this.end = moment(ev.end?.dateTime);
    this.own = !!(
      ev.organizer &&
      (ev.organizer.email === this.room.owner ||
        ev.creator?.email === this.room.owner)
    );
    this.invited = !!ev.attendees?.some((a) => a?.email === this.room.owner);
    this.anyoneCanAddSelf = !!ev.anyoneCanAddSelf;
    this.organizer = ev.organizer?.displayName ?? ev.organizer?.email ?? '';
    this.attendees = ev.attendees
      ?.filter((a) => a?.responseStatus !== 'declined')
      .map((a) => a?.displayName || a?.email)
      .filter(Boolean) as string[];
  };

  /**
   * @returns Indicates the client has the right to cancel the event or not
   */

  get cancelable(): boolean {
    return this.own;
  }

  /**
   * @returns The title of the event, if owner or invited to it,
   * otherwise the organizers name
   */

  get title(): string {
    return this.own || this.invited ? this.summary : this.organizer;
  }

  /**
   * @description Indicates, whether the time slot is free or an actual event
   */

  get isFree(): boolean {
    return this.type === 'gap';
  }

  /**
   * @returns The length of the event in minutes
   */

  get duration(): number {
    return this.end.diff(this.start, 'm');
  }

  /**
   * @returns The length of the event as a readable string
   */

  get humanizedDuration(): string {
    return moment.duration(this.duration, 'm').humanize();
  }

  /**
   * @returns The default summary 'Available' if free, or the actual events summary
   */

  get computedSummary(): string {
    return this.isFree ? 'Available' : this.summary;
  }

  /**
   * @returns Whether the event is bookable or not
   */

  get bookable(): boolean {
    return (
      this.isFree && this.remainingDuration >= 5
      // &&
      // this.start.diff(this.timer.time, 'm') < SLOT_OVERLAP_TIME
    );
  }

  /**
   * @returns The remaining minutes until the event ends
   */

  get remainingDuration(): number {
    if (this.start.isAfter(this.timer.time)) {
      return this.duration;
    }
    return this.end.diff(this.timer.time, 'm');
  }

  /**
   * @returns All possible booking time spans for the events duration
   */

  get slots(): Slot[] {
    const ret: Slot[] = BOOKING_SLOTS.filter(
      (s) => this.bookable && this.remainingDuration >= s,
    ).map((m) => {
      return {
        minutes: m,
        start: this.start?.toISOString(),
        end: this.start?.clone().add(m, 'm').toISOString(),
        bookable: this.bookable,
      };
    });
    if (!BOOKING_SLOTS.find((s) => s === this.remainingDuration)) {
      ret.push({
        minutes: this.remainingDuration > 15 ? 'Max' : this.remainingDuration,
        start: this.start?.toISOString(),
        end: this.end?.toISOString(),
        bookable: this.bookable,
      });
    }
    return ret;
  }

  /**
   * @description Cancels the Event
   */

  cancel = () => {
    this.room.cancel(this);
  };
}
