import styled from 'styled-components';

import { color } from '../../styles';

export const StyledFigure = styled.figure`
  .occupied {
    fill: ${color.jRed};
  }

  .circle-invisible {
    display: none;
  }
`;
