import styled, { keyframes } from 'styled-components';

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const StyledAnimationWrapper = styled.div`
  animation: ${fadeInAnimation} 300ms ease-out;
`;
