import { useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';

import type { CheckAuth } from './generated/CheckAuth';
import { useInit } from './hooks/useInit';
import { useRoomInStorageLoader } from './hooks/useRoomInStorageLoader';
import { useStore } from './hooks/useStore';
import useUserActivity from './hooks/useUserActivity';
import { useWifiCredentials } from './hooks/useWifiCredentials';
import { FloorPage } from './pages/FloorPage';
import { LoginPage } from './pages/LoginPage';
import { RoomPage } from './pages/RoomPage';
import { CHECK_AUTH } from './queries/checkAuth';

const pollInterval =
  process.env.NODE_ENV === 'production' ? 1000 * 10 : 1000 * 15;

export default function Root() {
  useInit();
  useRoomInStorageLoader();
  useWifiCredentials();

  const { data, loading } = useQuery<CheckAuth>(CHECK_AUTH, { pollInterval });

  const isDevMode = useStore((state) => state.isDevMode);
  const devicePosition = useStore((state) => state.devicePosition);
  const deviceFloorLevel = useStore((state) => state.deviceFloorLevel);
  const availableFloors = useStore((state) => state.availableFloors);
  const currentPage = useStore((state) => state.currentPage);
  const setCurrentPage = useStore((state) => state.setCurrentPage);

  const isUserActive = useStore((state) => state.isUserActive);
  const [roomTitle, setRoomTitle] = useState('');

  const defaultFloorLevel = availableFloors[0];
  const [currentFloorLevel, setCurrentFloorLevel] = useState<number>(
    deviceFloorLevel || defaultFloorLevel,
  );

  useUserActivity(defaultFloorLevel, deviceFloorLevel, setCurrentFloorLevel);

  const isAuthenticated = data?.authStatus?.status === 'true';

  const showRoomPage = useCallback(
    (title: string) => {
      setCurrentPage('room');
      setRoomTitle(title);
    },
    [setCurrentPage],
  );

  useEffect(() => {
    setCurrentFloorLevel(deviceFloorLevel || availableFloors[0]);
  }, [availableFloors, deviceFloorLevel]);

  useEffect(() => {
    if (!isUserActive && devicePosition) {
      showRoomPage(devicePosition);
    }
  }, [isUserActive, devicePosition, showRoomPage]);

  if (loading) return null;
  function handleBackToFloorPage() {
    setCurrentPage('floor');
  }

  if (!isAuthenticated && !isDevMode) {
    return <LoginPage status={data?.authStatus?.status} />;
  } else if (currentPage === 'floor') {
    return (
      <FloorPage
        availableFloors={availableFloors}
        devicePosition={devicePosition}
        selectedFloor={currentFloorLevel}
        onSelectRoom={showRoomPage}
        onSelectFloor={setCurrentFloorLevel}
      />
    );
  } else if (currentPage === 'room') {
    return (
      <RoomPage
        roomName={roomTitle}
        onBackToFloorPage={handleBackToFloorPage}
      />
    );
  } else {
    return <h2>Unknown Page</h2>;
  }
}
