import { useMutation } from '@apollo/client';

import { clearMeetingFromLocalStorage } from '../bookingController';
import type {
  CancelMutation,
  CancelMutationVariables,
} from '../generated/CancelMutation';
import type Event from '../models/Event';
import { CANCEL_MUTATION } from '../queries/roomData';
import { useStore } from './useStore';

export function useCancelBooking(meetingId: string | undefined) {
  const getEventById = useStore((state) => state.getEventById);
  const addPendingDeletionId = useStore((state) => state.addPendingDeletionId);

  const [cancelBookingMutation] = useMutation<
    CancelMutation,
    CancelMutationVariables
  >(CANCEL_MUTATION);

  async function cancelBooking() {
    if (!meetingId) throw Error('No meetingId');
    try {
      const event: Event | undefined = getEventById(meetingId);
      if (!event) return;
      cancelBookingMutation({
        variables: { eventId: event.id },
      })
        .then(event.cancel)
        .catch(console.error);
      clearMeetingFromLocalStorage(meetingId);
      addPendingDeletionId(meetingId);
    } catch (error) {
      console.warn(error);
      return String(error);
    }
  }

  return cancelBooking;
}
