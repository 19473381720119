import '../text-styles.css';

import { StyledContainer, StyledLabel } from './RemainingTime.styles';

type Props = {
  minutes: number;
  organizerMail: string;
};

const RemainingTime = ({ minutes, organizerMail }: Props) => {
  return (
    <StyledContainer>
      <StyledLabel className="subline-1 remaining-time-label">
        {`Ends in ${minutes} minutes`}
      </StyledLabel>
      <StyledLabel className="subline-1 mail">{organizerMail}</StyledLabel>
    </StyledContainer>
  );
};

export default RemainingTime;
