import React from 'react';

import { DevModeToggle, Input, Login } from './LoginSection.styles';

type Props = {
  isDevModeAvailable: boolean;
  isError: boolean;
  onChangeName: (newName: string) => void;
  onLoginRequest: () => void;
  onToggleDevMode: () => void;
  value: string;
};

const LoginSection: React.FC<Props> = ({
  isDevModeAvailable,
  isError,
  onChangeName,
  onLoginRequest,
  onToggleDevMode,
  value,
}) => {
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeName(event.target.value);
  };

  return (
    <>
      <Input
        type="text"
        placeholder="Device Name*"
        value={value}
        error={isError}
        onChange={handleNameChange}
      />
      <Login onClick={onLoginRequest}>Request Approval</Login>
      {isDevModeAvailable && (
        <DevModeToggle onClick={onToggleDevMode}>Dev Mode</DevModeToggle>
      )}
    </>
  );
};

export default LoginSection;
