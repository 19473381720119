import styled, { keyframes } from 'styled-components';

import { color } from '../../styles';

export const StyledCardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;
  min-height: 104px;
  gap: 8px;

  .card-headline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    width: calc(100% - 40px);
    min-height: 42px;
    padding-right: 24px;
  }
`;
export const StyledTitleInputWrapper = styled.div<{
  variant?: string;
}>`
  height: ${(p) => (!p.variant ? '45px' : '72px')};
  transition: height 0.3s ease-out;
  position: relative;

  input {
    opacity: ${(p) => (!p.variant ? '0' : '1')};
    transition: opacity 0.3s ease-out;
    ${(p) => (!p.variant ? 'pointer-events: none;' : '')}
  }

  div {
    opacity: ${(p) => (p.variant ? '0' : '1')};
    transition: opacity 0.3s ease-out;
    position: absolute;
    top: 0;
  }
`;
export const StyledInput = styled.input`
  width: 60vw;
  height: 66px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 72px;
  border: ${color.lightGrey};
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.05);
  padding-left: 26px;
  color: ${color.white};
  caret-color: ${color.blue2925C};

  -webkit-user-select: auto;
  user-select: auto;

  &:focus {
    outline-offset: -2px;
    outline: ${color.blue2925C} solid 2px;
  }

  &::placeholder {
    color: ${color.white};
  }
`;
const ButtonInAnimation = keyframes`
  from {
    opacity: 0;
    top: 27px;
  }
  to {
    opacity: 1;
    top: 0;
  }
`;
export const StyledActionButtonWrapper = styled.div<{ variant?: string }>`
  margin-top: 20px;
  position: relative;
  width: calc(100% - 40px);
  height: ${(p) => (!p.variant ? '0' : '54px')};
  transition: height 0.3s ease-out;

  button {
    position: absolute;
    right: 0;
    top: 0;
    height: 54px;
    animation: ${(p) => (p.variant === 'booking' ? ButtonInAnimation : null)}
      300ms ease-out;

    :first-child {
      transform: translateX(-196px);
    }

    :last-child {
    }
  }
`;
