import moment from 'moment';

import config from '../config';
import type { RoomsQuery_rooms } from '../generated/RoomsQuery';
import Event from './Event';
import type Timer from './Timer';

const { BOOKING_SLOTS, SLOT_OVERLAP_TIME } = config;

type RoomType = RoomsQuery_rooms;

/**
 *
 *
 * @export
 * @class Room
 */
export default class Room {
  // The calendar id of the {Room}
  id: string;

  // Route Id of the {Room}
  // route: number;

  // Reference to the parent {DataStore}
  // store: DataStore;

  timer: Timer;

  // The {Room}s name
  name: string;

  //  summary: string;

  // A list of all the {Room}s events
  events: Event[] = [];

  owner: string | null;

  /**
   * Creates an instance of Room.
   * @param timer
   * @param {Object} cal
   * @param owner
   */
  constructor(
    timer: Timer,
    cal: RoomsQuery_rooms | null,
    owner: string | null,
  ) {
    if (!cal) throw new Error('No calendar data provided');
    this.id = cal.calendarId;
    this.timer = timer;
    this.owner = owner;
    this.name = cal.name ?? '';
    this.updateEvents(cal);
  }

  /**
   * @returns The Rooms calendar id
   */

  get calendarId(): string {
    return this.id;
  }

  /**
   * @returns A with gap events enriched list of the {Room}s {Event}s
   */

  get timetable(): Event[] {
    const timetable: Event[] = [];
    const sortedEvents = this.events.sort((a, b) => {
      return a.start.diff(b.start);
    });

    // const now = this.store.timer.time;
    const now = Date.now();

    sortedEvents.forEach((ev, index) => {
      if (ev.end.isBefore(now) || ev.end.isSame(now)) return;

      if (ev.start.isAfter(now)) {
        const start =
          timetable.length === 0
            ? moment()
            : timetable[timetable.length - 1].end;
        if (ev.start.diff(start, 'm') > 0) {
          timetable.push(
            new Event(this, {
              start: { dateTime: start.toISOString() },
              end: { dateTime: ev.start.toISOString() },
              type: 'gap',
              id: `gap-${index}`,
              anyoneCanAddSelf: null,
              attendees: null,
              creator: null,
              organizer: null,
              owner: null,
              summary: null,
            }),
          );
        }
      }
      timetable.push(ev);
    });

    const lastEvent = timetable.length && timetable[timetable.length - 1];

    timetable.push(
      new Event(this, {
        type: 'gap',
        id: 'lastGap',
        start: {
          dateTime: lastEvent
            ? lastEvent.end.toISOString()
            : moment().toISOString(),
        },
        end: { dateTime: moment().endOf('day').toISOString() },
        anyoneCanAddSelf: null,
        attendees: null,
        creator: null,
        organizer: null,
        owner: null,
        summary: null,
      }),
    );

    return timetable;
  }

  /**
   * @returns A a cleaned version of the {Room}s name
   */

  get nameId(): string {
    return this.name.replace(/\s/g, '-');
  }

  /**
   * @returns The current {Event}
   */

  get current(): Event {
    return this.timetable[0];
  }

  /**
   * @returns The default title 'Available' if free, or the current events title
   */

  get title(): string {
    return this.current.isFree ? 'Available' : this.current.title;
  }

  /**
   * @returns The next upcoming {Event}, if there is one, otherwise false gets returned
   */

  get next() {
    return this.timetable.length >= 2 && this.timetable[1];
  }

  /**
   * @returns The {Room}s occupancy status as a reading string
   */

  get status(): string {
    // const { timer } = this.store;
    const time = new Date();
    return this.current.isFree
      ? `Free for 
          ${(this?.next && this.next.start.from(time, true)) || 'the day'}`
      : `Booked for ${this.nextGap?.start.from(time, true)}`;
  }

  /**
   * @description Indicates, whether the {Room} is hosting an event at the moment or not
   */

  get isFree(): boolean {
    return this.current.isFree;
  }

  /**
   * @description Indicates, whether the {Room} is bookable at the moment or not
   */

  get isBookable(): boolean {
    return (
      (this.current.isFree &&
        this.current.remainingDuration >= BOOKING_SLOTS[0]) ||
      (!this.current.isFree &&
        this.current.remainingDuration <= SLOT_OVERLAP_TIME &&
        this.next &&
        this.next.duration >= BOOKING_SLOTS[0])
    );
  }

  //
  // get bookAt(): string | void {
  //   if (!this.isBookable) return;
  //   if (this.current.isFree) return 'now';
  //   return `at ${this.current.end.format('HH:mm')}`;
  // }

  /**
   * @returns The next free gap {Event} or false, if not available
   */

  get nextGap() {
    return this.timetable.find((o) => o.isFree);
  }

  /**
   * @description Books a free time slot
   * @param  {string} start Starting time of the {Event}
   * @param  {string} end End time of the {Event}
   */

  book = (start: string, end: string) => {
    const ev: RoomType['items'][number] = {
      id: '#newBookedEvent',
      summary: 'Meeting',
      start: {
        dateTime: start,
      },
      end: {
        dateTime: end,
      },
      organizer: {
        email: this.owner,
        displayName: '',
      },
      attendees: [],
      anyoneCanAddSelf: false,
      owner: true,
      creator: null,
    };

    const event = new Event(this, ev);
    this.events.push(event);
  };

  /**
   * @description Updates/adds {Event}s in the {Room}s {Event}s list
   * @param  {Object} cal
   */

  updateEvents = (cal: RoomType) => {
    const trash = this.events.filter(
      (ev) => !cal.items?.find((e) => e?.id === ev.id),
    );

    trash.forEach((ev) => {
      if (ev.id === '#newBookedEvent') return;
      this.events.splice(this.events.indexOf(ev), 1);
    });

    this.onlyAcceptedEvents(cal.items).forEach((ev) => {
      let event = this.events.find((e) => e.id === ev.id);
      if (!event) {
        event = new Event(this, ev);
        this.events.push(event);
      } else {
        event.updateData(ev);
      }
    });
  };

  /**
   * Removes all not 'accepted' events from an array
   * @param  {Array<Object>} events
   * @returns A filtered Array of events
   */
  onlyAcceptedEvents = (events: RoomType['items']) => {
    return events.filter(
      (e) =>
        !e.attendees ||
        e.attendees.find(
          (a) => a?.email === this.id && a.responseStatus === 'accepted',
        ),
    );
  };

  /**
   * Cancels and removes an given {Event} from the {Room}
   * @param  {Event} event
   */

  cancel = (event: Event) => {
    this.events.splice(this.events.indexOf(event), 1);
  };
}
