import { useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';

import { BrandHeader } from '../../Components/BrandHeader';
import { LoaderWithInfo } from '../../Components/LoaderWithInfo';
import { LoginSection } from '../../Components/LoginSection';
import type { PublicName } from '../../generated/PublicName';
import { useStore } from '../../hooks/useStore';
import { PUBLIC_NAME, REQUEST_SIGNAGE_APPROVAL } from '../../queries/login';
import { Wrapper } from './StyledLoginPage';

type Props = {
  status: string | undefined | null;
};

export default function LoginPage({ status }: Props) {
  const [name, setName] = useState('');
  const [error, setError] = useState(false);
  const [requestApproval] = useMutation(REQUEST_SIGNAGE_APPROVAL);
  const publicData = useQuery<PublicName>(PUBLIC_NAME, {
    fetchPolicy: 'no-cache',
  });
  const isDevModeAvailable = useStore((state) => state.isDevModeAvailable);
  const toggleDevMode = useStore((state) => state.toggleDevMode);

  const [isPending, setIsPending] = useState(false);
  useEffect(() => {
    if (status === 'pending') {
      setIsPending(true);
    }
  }, [status]);

  const handleLoginRequest = useCallback(() => {
    if (name) {
      setIsPending(true);
      requestApproval({
        variables: { info: window.navigator.userAgent, name },
      }).catch(console.error);
      setError(false);
    } else {
      setError(true);
    }
  }, [name, requestApproval]);

  return (
    <Wrapper>
      <div>
        <BrandHeader publicData={publicData} />
        {isPending ? (
          <LoaderWithInfo />
        ) : (
          <LoginSection
            value={name}
            isError={error}
            onChangeName={setName}
            onLoginRequest={handleLoginRequest}
            isDevModeAvailable={isDevModeAvailable}
            onToggleDevMode={toggleDevMode}
          />
        )}
      </div>
    </Wrapper>
  );
}
