import './App.css';
import './Components/text-styles.css';

import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import React from 'react';

import { useOnlineState } from './hooks/useOnline';
import useUpdate from './hooks/useUpdate';
import { OfflinePage } from './pages/OfflinePage';
import Root from './Root';

// Initialize Apollo Client
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    // on amplify environments REACT_APP_SERVER_URL is expected to point to a configured server for this client
    // e.g. https://rooms-stage.ffw-playground.de or https://rooms.ffw-playground.de
    // it should be empty on a local development machine, so that the local proxy is able to circumvent CORS issues
    uri: `${
      process.env.REACT_APP_IS_NOT_DEPLOYED === 'true'
        ? ''
        : process.env.REACT_APP_SERVER_URL
    }/api/app`,
    credentials: 'include',
    fetchOptions: {
      mode: 'cors',
      credentials: 'include',
    },
  }),
  credentials: 'include',
  connectToDevTools: true,
});

function App() {
  useUpdate();
  const isOnline = useOnlineState();

  if (!isOnline) return <OfflinePage />;
  return (
    <ApolloProvider client={client}>
      <div className="App">
        <Root />
      </div>
    </ApolloProvider>
  );
}

export default App;
