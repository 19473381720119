import { useEffect, useRef } from 'react';
import { io, type Socket } from 'socket.io-client';

import { socketConformName } from '../utils/genericUtils';
import { useStore } from './useStore';

export default function useSockets(
  fetchRooms: (() => void) | null,
  fetchInitData: (() => void) | null,
  publicSettingsName: string | null,
  deviceId: string | null,
) {
  const socket = useRef<Socket | null>(null);
  const setSocketCurrentInfo = useStore((state) => state.setSocketCurrentInfo);
  const triggerUpdate = useStore((state) => state.triggerUpdate);

  useEffect(() => {
    const isSocketOnLocalhost =
      process.env.REACT_APP_SERVER_URL?.includes('localhost');

    if (!fetchRooms || !fetchInitData || !publicSettingsName || !deviceId)
      return;

    if (publicSettingsName) {
      if (!socket.current) {
        socket.current = io(
          `${process.env.REACT_APP_SERVER_URL}/${socketConformName(
            publicSettingsName,
          )}`,
          {
            withCredentials: !isSocketOnLocalhost, // Only send cookies if not on localhost
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 1000 * 60 * 60 * 6,
            reconnectionAttempts: 16,
          },
        );
      }

      socket.current.on('connect', () => {
        // eslint-disable-next-line no-console
        console.debug('Socket connected', publicSettingsName, deviceId);
        if (deviceId) {
          socket.current?.emit('roomId', deviceId);
        }
      });
      socket.current.on('roomUpdate', () => {
        fetchRooms();
        triggerUpdate();
      });
      socket.current.on('settingsUpdate', () => {
        fetchInitData();
        triggerUpdate();
      });

      setSocketCurrentInfo(socket.current);

      // eslint-disable-next-line no-console
      console.debug('Socket setup finished.', socket.current);
    } else {
      // eslint-disable-next-line no-console
      console.debug('No socket connection name, aborting.');
    }

    return () => {
      if (socket.current) {
        socket.current?.on('drain', () => {
          // eslint-disable-next-line no-console
          console.debug(
            'All events sent have been received by the server, disconnecting.',
          );
          socket.current?.disconnect();
        });
      }
    };
  }, [
    publicSettingsName,
    deviceId,
    fetchRooms,
    fetchInitData,
    setSocketCurrentInfo,
    triggerUpdate,
  ]);
}
