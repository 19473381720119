import '../text-styles.css';
import './time-period.css';
import '../../App.css';

export interface Props {
  endTime: number;
  startTime: number;
}

const locale = 'de';
const timeOptions: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
};

export function TimePeriod({ startTime, endTime }: Props) {
  const getStartTime = new Date(startTime);
  const localStartTime = getStartTime.toLocaleTimeString(locale, timeOptions);

  const getEndTime = new Date(endTime);
  const localEndTime = getEndTime.toLocaleTimeString(locale, timeOptions);

  return (
    <div className="title-3 time-period-text">
      <time>{localStartTime}- </time>
      <time>{localEndTime}</time>
    </div>
  );
}

export default TimePeriod;
