import '../text-styles.css';
import './time-picker.css';

import styled, { css } from 'styled-components';

import { color } from '../../styles';

/*
The TimePicker displays a list of duration options for a meeting and lets the user select one of them.
The duration options adjust to the time still available for a meeting.
*/

export type DurationOption =
  | '5'
  | '10'
  | '15'
  | '30'
  | '45'
  | '60'
  | '90'
  | '120'
  | 'Max';

export interface DurationOptions {
  durationOptions?: DurationOption[];
  onSelectedDurationOption: (selectedDurationOption?: DurationOption) => void;
  selectedDurationOption?: DurationOption;
}

const StyledContainer = styled.div<{ variant: string }>`
  display: flex;
  flex-direction: row;
  gap: 9px;
  margin-top: 16px;
  margin-bottom: 16px;

  @media (max-width: 1000px) {
    ${(p) =>
      p.variant === 'manyOptions' &&
      css`
        width: 440px;
        flex-wrap: wrap;
      `}
  }
`;

export function TimePicker({
  selectedDurationOption,
  durationOptions,
  onSelectedDurationOption,
}: DurationOptions) {
  const durationOptionsLength = durationOptions?.length || 0;

  return (
    <StyledContainer
      variant={durationOptionsLength > 6 ? 'manyOptions' : 'lessOptions'}
      className="time-picker-container"
    >
      {durationOptions?.map((durationOption) => {
        const isActiveTimeSlot = selectedDurationOption === durationOption;
        const backgroundColor = isActiveTimeSlot ? color.blue2925C : '#F5F5F5'; //className
        return (
          <button
            key={durationOption}
            className={`button-label-1 label ${
              durationOption === 'Max'
                ? 'time-picker-button-max'
                : 'time-picker-button-number'
            }`}
            style={{ backgroundColor }}
            onClick={() => onSelectedDurationOption(durationOption)}
          >
            {durationOption}
          </button>
        );
      })}
    </StyledContainer>
  );
}

export default TimePicker;
