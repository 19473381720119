import '../text-styles.css';
import './action-button.css';

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;

type Color = RGB | RGBA | HEX;

type ActionButtonProps = {
  actionButtonName?: string;
  backgroundColor?: string;
  borderColor?: string;
  color?: Color;
  isPrimary?: boolean;
  onClickHandler: () => void;
};

const ActionButton = ({
  isPrimary,
  actionButtonName,
  backgroundColor,
  borderColor,
  color,
  onClickHandler,
}: ActionButtonProps) => {
  return (
    <button
      onClick={onClickHandler}
      className={`action-button ${
        isPrimary
          ? 'button-label-2 primary-button'
          : 'button-label-4 secondary-button'
      }`}
      style={{
        backgroundColor,
        color,
        borderColor,
      }}
    >
      {actionButtonName}
    </button>
  );
};

export default ActionButton;
