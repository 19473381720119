import { useEffect } from 'react';

import { useStore } from './useStore';

export default function useUserActivity(
  defaultFloorLevel: number,
  deviceFloorLevel: number | null,
  setCurrentFloorLevel: (floorLevel: number) => void,
) {
  const timerInSeconds: number = parseInt(
    process.env.REACT_APP_USER_ACTIVITY_TIMEOUT || '60',
    10,
  );

  const setUserIsActive = useStore((state) => state.setUserActive);

  useEffect(() => {
    if (timerInSeconds === 0) return;

    let activityTimeout: NodeJS.Timeout;

    const resetTimer = () => {
      clearTimeout(activityTimeout);
      activityTimeout = setTimeout(() => {
        setUserIsActive(false);
        setCurrentFloorLevel(deviceFloorLevel || defaultFloorLevel);
      }, timerInSeconds * 1000);
    };

    const handleUserActivity = () => {
      setUserIsActive(true);
      resetTimer();
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('touchstart', handleUserActivity);
    window.addEventListener('touchend', handleUserActivity);

    resetTimer();

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('touchstart', handleUserActivity);
      window.removeEventListener('touchend', handleUserActivity);
      clearTimeout(activityTimeout);
    };
  }, [
    defaultFloorLevel,
    deviceFloorLevel,
    setCurrentFloorLevel,
    setUserIsActive,
    timerInSeconds,
  ]);
}
