import type { DurationOption } from '../Components/TimePicker';

export const selectDurationOption = (duration: number) => {
  switch (duration) {
    case 5:
      return '5';
    case 10:
      return '10';
    case 15:
      return '15';
    case 30:
      return '30';
    case 45:
      return '45';
    case 60:
      return '60';
    case 90:
      return '90';
    case 120:
      return '120';
    default:
      return 'Max';
  }
};

export const updateSelectedDurationOption = (
  durationOption: DurationOption | undefined,
  minutes: number,
) => {
  if (minutes === 4) {
    return 'Max';
  } else if (minutes === 9 && Number(durationOption) > 5) {
    return 'Max';
  } else if (minutes === 14 && Number(durationOption) > 10) {
    return 'Max';
  } else if (minutes === 29 && Number(durationOption) > 15) {
    return 'Max';
  } else if (minutes === 44 && Number(durationOption) > 30) {
    return 'Max';
  } else if (minutes === 59 && Number(durationOption) > 45) {
    return 'Max';
  } else if (minutes === 89 && Number(durationOption) > 60) {
    return 'Max';
  } else if (minutes === 119 && Number(durationOption) > 90) {
    return 'Max';
  } else {
    return durationOption;
  }
};
