import { useEffect, useState } from 'react';

export enum Orientation {
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
}

export default function useOrientation() {
  const [orientation, setOrientation] = useState<Orientation>(() =>
    window.matchMedia('(orientation: landscape)').matches
      ? Orientation.LANDSCAPE
      : Orientation.PORTRAIT,
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: landscape)');

    const updateOrientation = (event: MediaQueryListEvent) => {
      setOrientation(
        event.matches ? Orientation.LANDSCAPE : Orientation.PORTRAIT,
      );
    };

    // Check if mediaQuery supports addEventListener method
    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', updateOrientation);
    } else if (mediaQuery.addListener) {
      // Fallback for older Safari
      mediaQuery.addListener(updateOrientation);
    }

    return () => {
      // Check if mediaQuery supports removeEventListener method
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', updateOrientation);
      } else if (mediaQuery.removeListener) {
        // Fallback for older Safari
        mediaQuery.removeListener(updateOrientation);
      }
    };
  }, []);

  const isLandscape = orientation === Orientation.LANDSCAPE;
  const isPortrait = orientation === Orientation.PORTRAIT;

  return { orientation, isLandscape, isPortrait };
}
