import { useState } from 'react';

import { useCancelBooking } from '../../hooks/useCancelBooking';
import { Modal } from '../Modal';

type SlotOptionModalProps = {
  isOpen: boolean;
  meetingId?: string;
  onCloseModal: () => void;
  onEditModal: () => void;
};

export function SlotOptionModalFlow({
  isOpen,
  meetingId,
  onCloseModal,
  onEditModal,
}: SlotOptionModalProps) {
  const [isOpenSecondary, setIsOpenSecondary] = useState(false);
  const cancelBooking = useCancelBooking(meetingId);

  const closeModal = () => {
    onCloseModal();
    setIsOpenSecondary(false);
  };

  const clearCurrentRoom = () => {
    cancelBooking().catch(console.error);
    closeModal();
  };

  const editCurrentMeeting = () => {
    try {
      onEditModal();
      closeModal();
    } catch (err) {
      console.warn(err);
      return String(err);
    }
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onCloseModal={closeModal}
        onCtaPrimary={editCurrentMeeting}
        onCtaSecondary={() => setIsOpenSecondary(true)}
        ctaLabelPrimary="Edit Meeting"
        ctaLabelSecondary="Clear Room"
        headline="What do you want to do?"
      />
      <Modal
        isOpen={isOpenSecondary}
        onCloseModal={closeModal}
        onCtaPrimary={clearCurrentRoom}
        onCtaSecondary={closeModal}
        ctaLabelPrimary="Yes"
        ctaLabelSecondary="No"
        headline="Clear Room ?"
      />
    </div>
  );
}
export default SlotOptionModalFlow;
