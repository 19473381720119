import '../text-styles.css';

import { StyledContainer, StyledLabel } from './Duration.styles';

type Props = {
  minutes: number;
  organizerMail: string;
};

const Duration = ({ minutes, organizerMail }: Props) => {
  const durationLabelText = `Duration: ${minutes} minutes`;

  return (
    <StyledContainer>
      <StyledLabel className="subline-1">{durationLabelText}</StyledLabel>
      <StyledLabel className="subline-1 mail">{organizerMail}</StyledLabel>
    </StyledContainer>
  );
};

export default Duration;
