import { useEffect, useState } from 'react';

import type { DurationOption } from './Components/TimePicker';
import { useStore } from './hooks/useStore';
import type { Slot } from './pages/RoomPage/RoomPage';
import type { Meeting } from './types';
import {
  addOneMinuteToDate,
  roundUnixTimestampToMinutes,
  subtractOneMinuteFromDate,
} from './utils/date';
import { formatMeetingsFromRooms } from './utils/roomData';

const meetingDayStarts = new Date().setHours(0, 0, 0, 0);
const meetingDayEnds = new Date().setHours(23, 59, 0, 0);

export const PENDING = '#pending';

/**
 * Books a time slot for a meeting in a given room & sets the meeting data into the local storage.
 *
 * @param {Object} availableSlot - The available time slot to book, omitting 'title'.
 * @param {DurationOption | undefined} durationOption - The desired meeting duration.
 * @param {string} room - The room where the meeting will take place.
 * @param {string} title - The title of the meeting.
 * @param {number} currentTime - The current time in milliseconds since the epoch.
 * @param {number} minutesLeft - The number of minutes left in the available time slot.
 * @returns {string | undefined} - An error message if an error occurs during booking; otherwise, undefined.
 * @throws {Error} If an error occurs during booking, it is thrown with an error message.
 */
export const bookSlot = async (
  availableSlot: Omit<Slot, 'title'>,
  durationOption: DurationOption | undefined,
  room: string,
  title: string,
  currentTime: number,
  minutesLeft: number,
): Promise<string | undefined> => {
  const calculateDuration = () => {
    if (availableSlot.startTime <= currentTime) {
      return durationOption === 'Max' ? minutesLeft : Number(durationOption);
    }
    return durationOption === 'Max'
      ? availableSlot.duration
      : Number(durationOption);
  };
  const startTimeAvailableSlot = () => {
    if (availableSlot.startTime <= currentTime) {
      return (availableSlot.startTime = currentTime);
    }
    return availableSlot.startTime;
  };

  try {
    const dataLocalStorage = localStorage.getItem(`${room}`);
    const meetingsInRoom = JSON.parse(dataLocalStorage || '[]');
    const meeting = {
      id: PENDING + Date.now(),
      title: title,
      startTime: roundUnixTimestampToMinutes(startTimeAvailableSlot()),
      endTime: roundUnixTimestampToMinutes(
        availableSlot.startTime + calculateDuration() * 60000,
      ),
      room: availableSlot.room,
      duration: calculateDuration(),
    };
    meetingsInRoom.push(meeting);
    localStorage.setItem(
      `${availableSlot.room}`,
      JSON.stringify(meetingsInRoom),
    );
    window.dispatchEvent(new Event('storage'));
  } catch (err) {
    return String(err);
  }
};

export async function getMeetings(roomTitle: string): Promise<Slot[]> {
  return new Promise<Slot[]>((resolve) => {
    try {
      const meetingsInLocalStorage = localStorage.getItem(`${roomTitle}`);
      if (meetingsInLocalStorage) {
        const parsedMeetingsFromLocalStorage = JSON.parse(
          meetingsInLocalStorage,
        );
        if (Array.isArray(parsedMeetingsFromLocalStorage)) {
          resolve(parsedMeetingsFromLocalStorage);
        }
      }
      resolve([]);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err);
      resolve([]);
    }
  });
}

/**
 * gets all meetings and calculates free slots for a specific room
 * @param {string} roomTitle
 */
export async function getSlotCardData(roomTitle: string): Promise<Slot[]> {
  const meetings = await getMeetings(roomTitle);
  const meetingsRoom = meetings.filter((meeting) => meeting.room === roomTitle);

  const sortedMeetings = meetingsRoom
    .sort((a, b) => a.startTime - b.startTime)
    .map((meeting) => {
      return {
        ...meeting,
        startTime: roundUnixTimestampToMinutes(meeting.startTime),
        endTime: roundUnixTimestampToMinutes(meeting.endTime),
      };
    });

  let comparedMeetings: Slot[] = [];

  for (let i = 0; i < sortedMeetings.length; i++) {
    //check if next item exists
    if (sortedMeetings[i + 1] !== undefined) {
      //compare if there is no empty timeslot between meetings
      if (
        sortedMeetings[i].endTime === sortedMeetings[i + 1].startTime ||
        sortedMeetings[i].endTime ===
          subtractOneMinuteFromDate(sortedMeetings[i + 1].startTime)
      ) {
        comparedMeetings = [...comparedMeetings, sortedMeetings[i]];
      } else {
        comparedMeetings = [
          ...comparedMeetings,
          sortedMeetings[i],
          {
            id: '#free-between' + addOneMinuteToDate(sortedMeetings[i].endTime),
            room: roomTitle,
            startTime: addOneMinuteToDate(sortedMeetings[i].endTime),
            endTime: subtractOneMinuteFromDate(sortedMeetings[i + 1].startTime),
            duration:
              Math.floor(
                (sortedMeetings[i + 1].startTime - sortedMeetings[i].endTime) /
                  60000,
              ) - 2,
          },
        ];
      }
    } else {
      comparedMeetings = [...comparedMeetings, sortedMeetings[i]];
    }
  }
  //check if there is free time before first meeting
  if (comparedMeetings.length === 0) {
    comparedMeetings = [
      ...comparedMeetings,
      {
        id: '#free-slot-before-first' + roundUnixTimestampToMinutes(Date.now()),
        room: roomTitle,
        startTime: meetingDayStarts,
        endTime: subtractOneMinuteFromDate(meetingDayEnds),
        duration: Math.floor((meetingDayEnds - meetingDayStarts) / 60000),
      },
    ];
  } else {
    if (comparedMeetings[0].startTime > meetingDayStarts) {
      comparedMeetings = [
        {
          id: '#free-slot-before-first' + Date.now(),
          room: roomTitle,
          startTime: meetingDayStarts,
          endTime: subtractOneMinuteFromDate(comparedMeetings[0].startTime),
          duration: Math.floor(
            (comparedMeetings[0].startTime - meetingDayStarts) / 60000,
          ),
        },
        ...comparedMeetings,
      ];
    }
    //check if there is free time after last meeting
    if (
      meetingDayEnds > comparedMeetings[comparedMeetings.length - 1].endTime
    ) {
      comparedMeetings = [
        ...comparedMeetings,
        {
          id:
            '#free-slot-after' +
            addOneMinuteToDate(
              sortedMeetings[sortedMeetings.length - 1].endTime,
            ),
          room: roomTitle,
          startTime: addOneMinuteToDate(
            comparedMeetings[comparedMeetings.length - 1].endTime,
          ),
          endTime: meetingDayEnds,
          duration: Math.floor(
            (meetingDayEnds -
              comparedMeetings[comparedMeetings.length - 1].endTime) /
              60000,
          ),
        },
      ];
    }
  }

  return comparedMeetings;
}

const resolveMeetingRoomWithMeetingId = (meetingId: string): string | null => {
  for (let i = 0; i < localStorage.length; i++) {
    const key: string | null = localStorage.key(i);
    if (!key) continue;
    const item = localStorage.getItem(key);
    if (!item) continue;

    try {
      const meetings = JSON.parse(item);
      if (Array.isArray(meetings)) {
        const meetingWhichMatchingId: Meeting = meetings.find(
          (meeting) => meeting && meeting.id === meetingId,
        );
        if (meetingWhichMatchingId) {
          return meetingWhichMatchingId.room;
        }
      }
    } catch (e) {
      console.warn('Error parsing item for key:', key);
    }
  }
  return null;
};

export const clearMeetingFromLocalStorage = (meetingId: string | undefined) => {
  const roomTitle = resolveMeetingRoomWithMeetingId(meetingId || '');
  const dataLocalStorage = localStorage.getItem(`${roomTitle}`);
  const meetingsInRoom = JSON.parse(dataLocalStorage || '[]');
  const filteredMeetings = meetingsInRoom.filter(
    (meeting: Slot) => meeting.id !== meetingId,
  );
  localStorage.setItem(`${roomTitle}`, JSON.stringify(filteredMeetings));
  window.dispatchEvent(new Event('storage'));
};

export function getDurationOptions(availableMinutes: number): DurationOption[] {
  return (['5', '10', '15', '30', '45', '60', '90', '120'] as DurationOption[])
    .filter((durationOption) => availableMinutes >= Number(durationOption))
    .concat(['Max']);
}

export function UpdateRoomsOccupationStateOnFloorMap() {
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const rooms = useStore((state) => state.rooms);
  const roomTitles = useStore((state) => state.roomTitles);

  const meetingsOfCurrentDay = formatMeetingsFromRooms(rooms);

  const roomNames = (
    rooms.length > 0 ? rooms : roomTitles.map((name: string) => ({ name }))
  ).map(({ name }) => name);

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date().getTime()), 1000);

    return function cleanup() {
      clearInterval(timer);
    };
  }, [currentTime]);

  roomNames.forEach((name) => {
    const roomItemInSvg = [
      document.getElementById(`${name}-stroke`),
      document.getElementById(`${name}-text`),
      document.getElementById(`${name}-icon`),
      document.getElementById(`${name}-number`),
    ];

    const currentMeetingsInRoom = meetingsOfCurrentDay.filter(
      (meeting) => meeting.room === name,
    );

    const roomIsOccupiedNow = currentMeetingsInRoom.some(
      (meeting: Slot) =>
        meeting.startTime <= currentTime && meeting.endTime >= currentTime,
    );

    if (roomIsOccupiedNow) {
      roomItemInSvg?.forEach((item) => {
        item?.classList.add('occupied');
      });
    } else {
      roomItemInSvg?.forEach((item) => {
        item?.classList.remove('occupied');
      });
    }
  });
}

export function showActualPosition(room: string | null) {
  if (!room) return;
  const actualPosition = document.getElementById(`${room}-circle`);
  actualPosition?.classList.remove('circle-invisible');
}
