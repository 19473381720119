import styled, { keyframes } from 'styled-components';

import { color } from '../../styles';

export const Modal = styled.div`
  display: grid;
  place-items: center;
  position: fixed;
  top: 128px;
  left: 50%;
  transform: translateX(-50%);
  width: 668px;
  height: 456px;
  background-color: #303030;
  border: solid 1px #000000;
  border-radius: 20px;
  z-index: 10;
  padding: 24px 0;
`;

export const CloseButton = styled.img`
  position: absolute;
  top: 24px;
  right: 36px;
  cursor: pointer;
`;

export const TextContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 128px;
`;

export const Text = styled.span<{ textAlign: string }>`
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  font-weight: 600;
  color: ${color.white};
  text-align: ${(props) => props.textAlign};
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  animation: ${fadeIn} 0.5s ease-out forwards;
`;

export const QRCodeContainer = styled.div`
  display: grid;
  place-items: center;
  width: 240px;
  height: 240px;
  border-radius: 20px;
  background-color: ${color.background};
`;

export const QRCode = styled.div`
  width: 80%;
`;
