import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.1);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-left-color: #ffffff;

  animation: ${rotate} 1.2s linear infinite;
`;

const LoadingSpinner = () => {
  return <Spinner />;
};

export default LoadingSpinner;
