import './offline-page.css';

export const OfflinePage = () => {
  return (
    <div className="offlinePage">
      <h2>No Connection</h2>
    </div>
  );
};

export default OfflinePage;
