import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  width: 100vw;
  transform: translateY(-60%);
`;
