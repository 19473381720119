import '../../App.css';
import '../text-styles.css';
import './modal.css';

import { useState } from 'react';

import cancelIcon from '../../img/ModalCancelButton.svg';

type ModalProps = {
  ctaLabelPrimary?: string;
  ctaLabelSecondary?: string;
  headline?: string;
  isOpen?: boolean;
  onCloseModal: () => void;
  onCtaPrimary?: () => void;
  onCtaSecondary?: () => void;
};
const Modal = ({
  headline,
  isOpen,
  onCloseModal,
  onCtaPrimary,
  onCtaSecondary,
  ctaLabelPrimary,
  ctaLabelSecondary,
}: ModalProps) => {
  const [ctaFailMsg, setCtaFailMsg] = useState<string | undefined | void>();

  if (!isOpen) return null;

  const onClick = (callBack: ModalProps['onCtaPrimary' | 'onCtaSecondary']) => {
    if (callBack) {
      const result = callBack();
      setCtaFailMsg(result);
    }
  };

  const onClose = () => {
    {
      setCtaFailMsg('');
      onCloseModal();
    }
  };

  return (
    <div className="modal modal-text-style">
      <div className="modal-header">
        <div className="header-question">{headline}</div>
        <img
          onClick={onClose}
          className="header-cancel-button"
          src={cancelIcon}
          alt="X"
        ></img>
      </div>
      <div className="button-wrapper">
        {ctaFailMsg ? (
          ctaFailMsg
        ) : (
          <>
            <button
              className="
                    modal-text-style
                    cta-button-primary"
              onClick={() => onClick(onCtaPrimary)}
            >
              {ctaLabelPrimary}
            </button>
            <button
              className="
                    modal-text-style
                    cta-button-secondary"
              onClick={() => onClick(onCtaSecondary)}
            >
              {ctaLabelSecondary}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
