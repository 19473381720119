import '../text-styles.css';

import { useSwiper } from 'swiper/react';

import useOrientation, { Orientation } from '../../hooks/useOrientation';
import { ordinalSuffix } from '../../utils/genericUtils';
import type { FloorMap } from '../Floor/Floor';
import {
  StyledLine,
  StyledNavigation,
  StyledNavigationItemContainer,
} from './FloorNavigation.styles';

export type Props = {
  floors: FloorMap[];
  selectedLevel: number;
};

const FloorNavigation = ({ floors, selectedLevel }: Props) => {
  const { isLandscape } = useOrientation();
  const swiper = useSwiper();

  const renderList = (floorsToRender: FloorMap[], reversed = false) => {
    return floorsToRender.map(({ level }, index) => {
      const i = reversed ? floors.length - 1 - index : index;
      const activeLevel = selectedLevel === level;
      return (
        <li
          key={'nav-line-' + i}
          onClick={() => {
            swiper.slideTo(i);
          }}
        >
          <div className={activeLevel ? 'floor-infos big' : 'floor-infos'}>
            <div className={activeLevel ? 'label-1' : 'label-1 small'}>
              {ordinalSuffix(floors[i].level)}
            </div>
            <div className={activeLevel ? 'label-2' : 'label-3'}>Floor</div>
          </div>
        </li>
      );
    });
  };

  const orientation = isLandscape
    ? Orientation.LANDSCAPE
    : Orientation.PORTRAIT;
  const renderedList =
    orientation === Orientation.LANDSCAPE
      ? renderList(floors)
      : renderList(floors, true).reverse();

  return (
    <StyledNavigation variant={orientation}>
      <StyledLine variant={orientation} />
      <StyledNavigationItemContainer variant={orientation}>
        {renderedList}
      </StyledNavigationItemContainer>
    </StyledNavigation>
  );
};

export default FloorNavigation;
