import styled from 'styled-components';

import { color } from '../../styles';

export const StyledBackButton = styled.button`
  border: none;
  padding: 0;
  cursor: pointer;

  width: 54px;
  height: 54px;
  background-color: ${color.blue2925C};
  display: grid;
  place-items: center;
  margin-left: 20px;
  margin-right: 24px;
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  span {
    font-size: 1.5rem;
    margin-left: 1rem;
  }
  button {
    width: 3rem;
    height: 2rem;
  }
`;
