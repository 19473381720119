import type { InitQuery_floors } from '../generated/InitQuery';
import type Room from '../models/Room';
import type { Meeting } from '../types';
import { createTimePeriodForToday } from './date';
import { b64Decode } from './genericUtils';

export function prepareFloor(floor: InitQuery_floors | null) {
  if (!floor) return null;
  const { floorNumber, id, map } = floor;
  return {
    floorNumber,
    id,
    map: {
      horizontal: b64Decode(map.horizontal),
      portrait: b64Decode(map.portrait),
    },
  };
}

export function formatMeetingsFromRooms(rooms: Room[]) {
  const roomEvents = rooms.slice().flatMap((room) => room.events);
  const meetingsOfCurrentDay: Meeting[] = roomEvents.map((event) => ({
    id: event.id,
    room: event.room.name,
    title: event.summary,
    ...createTimePeriodForToday(
      event.start.format('HH:mm'),
      event.end.format('HH:mm'),
    ),
    duration: event.duration,
    organizer: event.organizer,
  }));
  return meetingsOfCurrentDay;
}

export function findDeviceFloorLevelInMap(
  floors: InitQuery_floors[],
  devicePosition: string | null,
): number | null {
  if (!devicePosition) return null;
  const deviceFloorLevel = floors
    // Get the floor that contains same name as the device position (room-name)
    .map((floor) => {
      const containsDevice = floor.map.horizontal
        // Search for element with id that contains the name of the matching room
        .match(/id="([^"]*)"/g)
        ?.some((id) => id.includes(devicePosition));
      // If the room is found, return the floor number tied to the floor
      return containsDevice ? floor.floorNumber : null;
    })
    .find((floor) => floor !== null);
  return deviceFloorLevel ?? null;
}
