import './slot-card.css';
import '../text-styles.css';
import '../../App.css';

import { useEffect, useRef, useState } from 'react';

import { bookSlot, getDurationOptions, PENDING } from '../../bookingController';
import { useBookRoom } from '../../hooks/useBookRoom';
import { useCancelBooking } from '../../hooks/useCancelBooking';
import { useCurrentTime } from '../../hooks/useCurrentTime';
import useDurationOptions from '../../hooks/useDurationOptions';
import { useStore } from '../../hooks/useStore';
import type { Slot } from '../../pages/RoomPage/RoomPage';
import { getMinutesUntil } from '../../utils/date';
import { updateSelectedDurationOption } from '../../utils/durationOption';
import { ActionButton } from '../ActionButton';
import { Availability } from '../Availability';
import { Duration } from '../Duration';
import { LoadingSpinner } from '../LoadingSpinner';
import { OptionButton } from '../OptionButton';
import { RemainingTime } from '../RemainingTime';
import { SlotTitle } from '../SlotTitle';
import { TimePeriod } from '../TimePeriod';
import { TimePicker } from '../TimePicker';
import {
  StyledActionButtonWrapper,
  StyledCardContentWrapper,
  StyledInput,
  StyledTitleInputWrapper,
} from './SlotCard.styles';

export interface Props extends Slot {
  editingMode: boolean;
  onCancelButton: () => void;
  slotOptionId: string | undefined;
}

export type DisplayMode = 'available' | 'booked' | 'editing';

function inferDisplayMode({ title, editingMode }: Props): DisplayMode {
  if (editingMode) return 'editing';
  return !title ? 'available' : 'booked';
}

const SlotCard = ({ ...slot }: Props) => {
  const {
    title,
    id,
    startTime,
    endTime,
    duration,
    room,
    organizer,
    optionButtonHandler,
    editingMode,
    onCancelButton,
  } = slot;
  const [displayMode, setDisplayMode] = useState<DisplayMode>(
    inferDisplayMode(slot),
  );
  const bookRoom = useBookRoom();
  const cancelBooking = useCancelBooking(id);
  const currentTime = useCurrentTime();

  const triggerUpdate = useStore((state) => state.triggerUpdate);

  const isBooked = displayMode === 'booked';
  const isEditing = displayMode === 'editing';
  const isAvailable = displayMode === 'available';

  const isCurrentSlot = currentTime >= startTime && currentTime < endTime;
  const minutesLeft = getMinutesUntil(currentTime, endTime);
  const showDuration = (isBooked || isEditing) && currentTime < startTime;
  const showTimePicker = isEditing || isAvailable;
  const showRemainingTime = (isBooked || isEditing) && isCurrentSlot;
  const isPending = id?.includes(PENDING);
  const isLocalBooking =
    (organizer?.includes('Cellular Rooms') ||
      organizer?.includes('- Hamburg')) ??
    false;

  const { selectedDurationOption, setSelectedDurationOption } =
    useDurationOptions({
      editingMode,
      duration,
      isCurrentSlot,
      minutesLeft,
    });

  useEffect(() => {
    setDisplayMode(inferDisplayMode(slot));
  }, [slot]);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    selectedDurationOption && inputRef.current?.focus();
  }, [selectedDurationOption]);

  let organizerEmail =
    process.env.REACT_APP_LOCAL_ORGANIZER_NAME ?? 'booked with app';

  if (
    organizer &&
    !(
      organizer.includes('calendar.google.com') ||
      organizer === 'Cellular Rooms'
    )
  ) {
    organizerEmail = organizer;
    // If organizer is an empty string, it means that the slot was booked with a third-party app most likely with Personio
  } else if (!organizer) {
    organizerEmail =
      process.env.REACT_APP_PERSONIO_ORGANIZER_NAME ??
      'booked with third-party app';
  }

  const durationUntilDayEnds = isCurrentSlot
    ? Math.round(minutesLeft)
    : duration;

  const startTimeUnix = isCurrentSlot ? currentTime : startTime;

  async function onBook(isEditing: boolean) {
    const summary: string = inputRef?.current?.value || 'Meeting';

    if (isEditing) {
      await cancelBooking();
    }

    bookRoom(
      room,
      selectedDurationOption,
      startTimeUnix,
      durationUntilDayEnds,
      summary,
    );

    bookSlot(
      slot,
      selectedDurationOption,
      room,
      summary,
      currentTime,
      minutesLeft,
    )
      .then(() => triggerUpdate())
      .catch(console.error);
  }

  function onCancel() {
    onCancelButton();
    setSelectedDurationOption(undefined);
  }

  return (
    <section className={`card-wrapper ${isBooked && 'opacity-30'}`}>
      <div className="card-time title-1">
        {startTime > currentTime ? (
          <TimePeriod startTime={startTime} endTime={endTime} />
        ) : (
          <TimePeriod startTime={currentTime} endTime={endTime} />
        )}
      </div>
      <StyledCardContentWrapper>
        <div className="card-headline">
          {(() => {
            switch (displayMode) {
              case 'available':
                return (
                  <StyledTitleInputWrapper
                    variant={selectedDurationOption && 'booking'}
                  >
                    <StyledInput
                      type="text"
                      className="input-field-text-1"
                      placeholder="Add meeting title (optional)"
                      defaultValue="" // no defaultValue because only the placeholder should be shown here
                      ref={inputRef}
                    />
                    <SlotTitle title={title} />
                  </StyledTitleInputWrapper>
                );
              case 'editing':
                return (
                  <StyledTitleInputWrapper variant="editing">
                    <StyledInput
                      type="text"
                      className="input-field-text-1"
                      placeholder="Add meeting title (optional)"
                      defaultValue={title} // no defaultValue because only the placeholder should be shown here
                      ref={inputRef}
                    />
                    <SlotTitle title={title} />
                  </StyledTitleInputWrapper>
                );
              case 'booked':
                return <SlotTitle title={title} />;
              default:
                return null;
            }
          })()}
          {title &&
            (isPending ? (
              <LoadingSpinner />
            ) : (
              isLocalBooking && (
                <OptionButton onClickHandler={optionButtonHandler} />
              )
            ))}
        </div>

        {showDuration && (
          <Duration minutes={duration} organizerMail={organizerEmail} />
        )}
        {isAvailable && <Availability minutes={durationUntilDayEnds} />}
        {showTimePicker && (
          <TimePicker
            durationOptions={
              // only the current slot is influenced by the current time, other slots lie in the future
              // TODO getDurationOptions has to be executed every minute to re use the available slots on break from e.g. 90 to 89 duration, where the 90 option would be removed
              isCurrentSlot
                ? getDurationOptions(minutesLeft)
                : getDurationOptions(duration)
            }
            onSelectedDurationOption={setSelectedDurationOption}
            selectedDurationOption={
              isCurrentSlot
                ? updateSelectedDurationOption(
                    selectedDurationOption,
                    minutesLeft,
                  )
                : selectedDurationOption
            }
          />
        )}
        {showRemainingTime && (
          <RemainingTime
            organizerMail={organizerEmail}
            minutes={Math.round(minutesLeft)}
          />
        )}
        <hr />
        <StyledActionButtonWrapper
          variant={
            isEditing ? 'editing' : selectedDurationOption ? 'booking' : ''
          }
          className={`card-button-content${
            selectedDurationOption || isEditing ? ' cancel' : ''
          }`}
        >
          {(selectedDurationOption || isEditing) && (
            <>
              <ActionButton
                isPrimary={false}
                onClickHandler={onCancel}
                actionButtonName="Cancel"
              />
              <ActionButton
                isPrimary={true}
                // TODO DANICA: set to always use max time, implement logic for selected duration option
                onClickHandler={() => onBook(isEditing).catch(console.error)}
                actionButtonName="Book Room"
              />
            </>
          )}
        </StyledActionButtonWrapper>
      </StyledCardContentWrapper>
    </section>
  );
};

export default SlotCard;
