import moment from 'moment';

/**
 *
 *
 * @export
 * @class Timer
 */
export default class Timer {
  seconds!: number;

  constructor() {
    this.tick();
    setInterval(this.tick, 1000);
  }

  get minutes(): number {
    return Math.floor(this.seconds / 60);
  }

  get time(): moment.Moment {
    return moment(this.minutes * 60 * 1000);
  }

  tick = () => {
    this.seconds = Math.floor(Date.now() / 1000);
  };
}
