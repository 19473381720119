import '../text-styles.css';

type Props = {
  title?: string;
};

export function SlotTitle({ title: bookedTitle }: Props) {
  return (
    <div className="title-wrapper title-1 ">
      {(bookedTitle &&
        (bookedTitle.length > 45
          ? `${bookedTitle?.slice(0, 45)}...`
          : bookedTitle)) ||
        'Available'}
    </div>
  );
}

export default SlotTitle;
