import styled, { css } from 'styled-components';

import { Orientation } from '../../hooks/useOrientation';
import { color } from '../../styles';

export const StyledNavigation = styled.nav<{ variant: Orientation }>`
  position: fixed;
  color: ${color.white};
  ${(p) =>
    p.variant === Orientation.LANDSCAPE
      ? css`
          /*landscape*/
          bottom: 8%;
          height: 76px;
          width: 90vw;
        `
      : css`
          /*portrait*/
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          left: 8%;
          top: 0;
          height: calc(90vh - 90px);
          margin-top: 90px;
        `}
`;
export const StyledNavigationItemContainer = styled.ul<{
  variant: Orientation;
}>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  list-style-type: none;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;

  ${(p) =>
    p.variant === Orientation.LANDSCAPE
      ? css`
          /*landscape*/
          flex-direction: row;
          padding: 10px 0 0 120px;
        `
      : css`
          /*portrait*/
          flex-direction: column;
          height: 100%;
          width: 0;
          transform: translateX(-65px) translateY(4vh);
        `}
  .label-1 {
    ${(p) =>
      p.variant === Orientation.LANDSCAPE
        ? css`
            /*landscape*/
            font-size: 80px;
            transition: font-size 300ms ease-in-out;
          `
        : css`
            /*portrait*/
            font-size: 80px;
            transition: font-size 300ms ease-in-out;
          `}
  }

  .label-1.small {
    font-size: 40px;
  }

  .floor-infos {
    padding: 10px 5px;
    height: 60px;
    background: ${color.background};
    line-height: 35px;
    transition: all 300ms ease-in-out;
  }

  .floor-infos.big {
    height: 80px;
    background: ${color.background};
    line-height: 50px;
    ${(p) =>
      p.variant === Orientation.LANDSCAPE
        ? css`
            /*landscape*/
            padding: 15px 5px;
            transform: translateY(-10px);
          `
        : css`
            /*portrait*/
            padding: 20px 5px;
            transform: translate(-20px, 10px);
          `}
  }
`;
export const StyledLine = styled.div<{ variant: Orientation }>`
  position: absolute;
  z-index: -1;
  background: ${color.blue2925C};
  ${(p) =>
    p.variant === Orientation.LANDSCAPE
      ? css`
          /*landscape*/
          width: 100vw;
          height: 4px;
          left: 0;
          bottom: 20%;
        `
      : css`
          /*portrait*/
          width: 4px;
          height: 100vh;
          left: 20%;
          top: 0;
        `}
`;
