import { useEffect, useState } from 'react';

import type { DurationOption } from '../Components/TimePicker';
import {
  selectDurationOption,
  updateSelectedDurationOption,
} from '../utils/durationOption';

type UseDurationOptionsProps = {
  duration: number;
  editingMode: boolean;
  isCurrentSlot: boolean;
  minutesLeft: number;
};

const useDurationOptions = ({
  editingMode,
  duration,
  isCurrentSlot,
  minutesLeft,
}: UseDurationOptionsProps) => {
  const [selectedDurationOption, setSelectedDurationOption] = useState<
    DurationOption | undefined
  >(editingMode ? selectDurationOption(duration) : undefined);

  useEffect(() => {
    setSelectedDurationOption(
      editingMode ? selectDurationOption(duration) : undefined,
    );
  }, [editingMode, duration]);

  useEffect(() => {
    if (isCurrentSlot) {
      setSelectedDurationOption(
        updateSelectedDurationOption(selectedDurationOption, minutesLeft),
      );
    }
  }, [isCurrentSlot, minutesLeft, selectedDurationOption]);

  return { selectedDurationOption, setSelectedDurationOption };
};

export default useDurationOptions;
