import type { ReactNode } from 'react';

import {
  showActualPosition,
  UpdateRoomsOccupationStateOnFloorMap,
} from '../../bookingController';
import { StyledFigure } from './Floor.styles';

export type FloorMap = {
  level: number;
  map: ReactNode;
  meetingRooms?: string[];
};

type Props = {
  currentPosition: string | null;
  onSelectRoom: (title: string) => void; // TODO @Lisa: where to obtain this information, is useRoom sufficient?
} & FloorMap;

const Floor = ({ map: floorMap, onSelectRoom, currentPosition }: Props) => {
  UpdateRoomsOccupationStateOnFloorMap();

  const handleFloorClick = (targetId: string) => {
    const inferredRoom = targetId.split('-')[0];
    if (inferredRoom) {
      onSelectRoom(inferredRoom);
    }
  };

  showActualPosition(currentPosition);

  return (
    <StyledFigure
      onClick={(e) => handleFloorClick((e.target as HTMLElement).id)}
      dangerouslySetInnerHTML={{
        __html: (floorMap as string) || 'loading',
      }}
    />
  );
};

export default Floor;
