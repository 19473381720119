import { useMutation } from '@apollo/client';

import type { DurationOption } from '../Components/TimePicker';
import type {
  BookMutation_bookRoom,
  BookMutationVariables,
} from '../generated/BookMutation';
import { BOOK_MUTATION } from '../queries/roomData';
import { useStore } from './useStore';

function formatTimeRangeToISO(
  selectedDurationOption: DurationOption | undefined,
  startTimeUnix: number,
  durationUntilDayEndsInMinutes: number,
): { endTime: string; startTime: string } {
  const calculatedDurationInMinutes =
    selectedDurationOption === 'Max'
      ? durationUntilDayEndsInMinutes
      : parseInt(selectedDurationOption ?? '0', 10);
  const currentTimeAsDateObject = new Date(startTimeUnix);
  const startTime = currentTimeAsDateObject.toISOString();
  const endTimeAsUnixTimestamp = currentTimeAsDateObject.setMinutes(
    currentTimeAsDateObject.getMinutes() + calculatedDurationInMinutes,
  );
  const endTime = new Date(endTimeAsUnixTimestamp).toISOString();
  return { startTime, endTime };
}

export function useBookRoom() {
  const getRoom = useStore((state) => state.getRoom);

  const [bookRoomMutation] = useMutation<
    BookMutation_bookRoom,
    BookMutationVariables
  >(BOOK_MUTATION);

  function bookRoom(
    roomName: string,
    selectedDurationOption: DurationOption | undefined,
    startTimeUnix: number,
    durationUntilDayEndsInMinutes: number,
    summary: string,
  ) {
    const room = getRoom(roomName);
    if (!room) return;
    const { startTime, endTime } = formatTimeRangeToISO(
      selectedDurationOption,
      startTimeUnix,
      durationUntilDayEndsInMinutes,
    );
    room.book(startTime, endTime);
    bookRoomMutation({
      variables: {
        start: startTime,
        end: endTime,
        attendees: room.id,
        summary,
      },
    }).catch(console.error);
  }

  return bookRoom;
}
