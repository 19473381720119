import type { ApolloError } from '@apollo/client';
import type { Socket } from 'socket.io-client';
import { create } from 'zustand';

import type { InitQuery, InitQuery_floors } from '../generated/InitQuery';
import { INIT_QUERY_MOCK } from '../mocks/graphQLMocks';
import type Event from '../models/Event';
import type Room from '../models/Room';
import { findDeviceFloorLevelInMap, prepareFloor } from '../utils/roomData';

type State = {
  availableFloors: number[];
  currentPage: 'floor' | 'room';
  defaultPosition: string | null;
  deviceFloorLevel: number | null;
  deviceId: string | null;
  devicePosition: string | null;
  floors: InitQuery_floors[];
  initData: {
    data: InitQuery | undefined;
    error: ApolloError | undefined;
    loading: boolean;
  };
  isDevMode: boolean;
  isDevModeAvailable: boolean;
  isUserActive: boolean;
  pendingDeletionIds: string[];
  primaryId: string | null;
  publicSettingsName: string | null;
  roomTitles: string[];
  rooms: Room[];
  socketCurrentInfo: Socket | null;
  update: number;
  wifiQrCode: string | null;
  wifiSettings: {
    SSID: string | null;
    password: string | null;
  } | null;
};

type Actions = {
  addPendingDeletionId: (meetingId: string) => void;
  debugLog: () => void;
  fetchInitData: (() => void) | null;
  fetchRooms: (() => void) | null;
  getEventById: (eventId: string) => Event | undefined;
  getRoom: (roomName: string | null) => Room | undefined;
  loadDeviceData: () => void;
  loadInitData: () => void;
  setCurrentPage: (currentPage: 'floor' | 'room') => void;
  setFetchInitData: (fetchInitData: (() => void) | null) => void;
  setFetchRooms: (fetchRooms: (() => void) | null) => void;
  setInitData: (
    data: InitQuery | undefined,
    loading: boolean,
    error: ApolloError | undefined,
  ) => void;
  setRooms: (rooms: Room[]) => void;
  setSocketCurrentInfo: (socketCurrentInfo: Socket | null) => void;
  setUserActive: (isUserActive: boolean) => void;
  setWifiSettings: (
    wifiSettings: {
      SSID: string | null;
      password: string | null;
    } | null,
    wifiQrCode: string | null,
  ) => void;
  toggleDevMode: () => void;
  triggerUpdate: () => void;
};

export const useStore = create<State & Actions>((set, get) => ({
  // STORE START

  isDevMode: false,

  isDevModeAvailable: process.env.REACT_APP_IS_NOT_DEPLOYED === 'true',
  toggleDevMode: () => set((state) => ({ isDevMode: !state.isDevMode })),
  isUserActive: true,
  setUserActive: (isUserActive) => {
    if (get().isUserActive === isUserActive) return;
    set(() => ({ isUserActive }));
  },

  update: Date.now(),
  triggerUpdate: () => {
    setTimeout(() => {
      set(() => ({ update: Date.now() }));
    }, 250);
  },

  currentPage: 'floor',
  setCurrentPage: (currentPage) => set(() => ({ currentPage })),

  availableFloors: [],
  defaultPosition: null,
  deviceFloorLevel: null,
  deviceId: null,
  devicePosition: null,
  fetchInitData: null,
  fetchRooms: null,
  floors: [],
  initData: { data: undefined, loading: true, error: undefined },
  primaryId: null,
  publicSettingsName: null,
  socketCurrentInfo: null,
  rooms: [],
  roomTitles: [],

  getRoom: (roomName: string | null) => {
    const { rooms } = get();
    return rooms.find(
      (room) => room?.name?.toLowerCase() === roomName?.toLowerCase(),
    );
  },

  getEventById: (eventId: string) => {
    const { rooms } = get();
    const events = rooms.flatMap((room) => room.events);
    return events.find((event) => event.id === eventId);
  },

  setInitData: (data, loading, error) => {
    set(() => ({
      initData: { data, loading, error },
    }));
    if (error) console.error(error);
    get().loadInitData();
  },

  loadInitData: () => {
    const { initData, isDevMode } = get();
    const initResponse = isDevMode ? INIT_QUERY_MOCK : initData.data;
    if (!initResponse) return;
    const floors = (initResponse.floors ?? [])
      .map(prepareFloor)
      .reverse()
      .filter(Boolean) as InitQuery_floors[];
    const { primaryId, deviceId, defaultPosition } = initResponse;
    const availableFloors = floors.map((floor) => floor.floorNumber).reverse();
    const publicSettingsName = initResponse.publicSettings?.name ?? null;
    set(() => ({
      availableFloors,
      defaultPosition,
      deviceId,
      floors,
      primaryId,
      publicSettingsName,
    }));
    get().debugLog();
  },

  loadDeviceData: () => {
    const { rooms, defaultPosition } = get();
    const room = rooms.find((room) => room.calendarId === defaultPosition);
    const devicePosition = room?.name ?? null;
    const deviceFloorLevel = findDeviceFloorLevelInMap(
      get().floors,
      devicePosition,
    );
    set(() => ({ deviceFloorLevel, devicePosition }));
  },

  setFetchInitData: (fetchInitData) => set(() => ({ fetchInitData })),

  setFetchRooms: (fetchRooms) => set(() => ({ fetchRooms })),

  setSocketCurrentInfo: (socketCurrentInfo) => {
    set(() => ({ socketCurrentInfo }));
    get().debugLog();
  },

  setRooms: (rooms) => {
    set(() => ({ rooms }));
    const roomTitles = rooms.map((room) => room.name);
    set(() => ({ roomTitles }));
  },

  // storing Ids of manually deleted meetings, to exclude these from a refetch
  pendingDeletionIds: [],
  addPendingDeletionId: (meetingId) => {
    const limit = 20;
    const { pendingDeletionIds } = get();
    pendingDeletionIds.push(meetingId);
    // since every manual meeting deletion would add an entry to the array,
    // the least significant entry will be deleted if array at limit
    if (pendingDeletionIds.length > limit) pendingDeletionIds.shift();
    set(() => ({
      pendingDeletionIds,
    }));
  },

  wifiSettings: null,
  wifiQrCode: null,
  setWifiSettings: (wifiSettings, wifiQrCode) =>
    set(() => ({ wifiSettings, wifiQrCode })),

  debugLog: () => {
    setTimeout(() => {
      const store = get();
      // eslint-disable-next-line no-console
      console.debug('--- debugLog ---');
      Object.entries(store)
        .filter(([_key, value]) => typeof value !== 'function')
        .forEach(([key, value]) => {
          // eslint-disable-next-line no-console
          console.debug(key, value);
        });
      // eslint-disable-next-line no-console
      console.debug('--- debugLog ---');
      // timeout is needed to make sure all dependencies are loaded,
      // e.G. establishing ws-connection
    }, 200);
  },

  // STORE END
}));
