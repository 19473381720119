import { Loader } from '../Loader';
import { Info } from './LoaderWithInfo.styles';

const LoaderWithInfo = () => {
  return (
    <>
      <Loader />
      <Info>Waiting for approval. Hang in there!</Info>
    </>
  );
};

export default LoaderWithInfo;
