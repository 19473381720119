import { useStore } from '../../hooks/useStore';
import cancelIcon from '../../img/ModalCancelButton.svg';
import {
  Backdrop,
  CloseButton,
  Modal,
  QRCode,
  QRCodeContainer,
  Text,
  TextContainer,
} from './WifiModal.styles';

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
};

const WifiModal = ({ isOpen, onCloseModal }: Props) => {
  const wifiSettings = useStore((state) => state.wifiSettings);
  const wifiQrCode = useStore((state) => state.wifiQrCode);

  if (!isOpen) return null;

  return (
    <>
      <Modal>
        <CloseButton
          onClick={onCloseModal}
          className="header-cancel-button"
          src={cancelIcon}
          alt="X"
        ></CloseButton>
        <QRCodeContainer>
          {wifiQrCode && (
            <QRCode dangerouslySetInnerHTML={{ __html: wifiQrCode }} />
          )}
        </QRCodeContainer>
        <TextContainer>
          <Text textAlign="left">WiFi Name:</Text>
          <Text textAlign="right">{wifiSettings?.SSID ?? '-'}</Text>
          <Text textAlign="left">Password:</Text>
          <Text textAlign="right">{wifiSettings?.password ?? '-'}</Text>
        </TextContainer>
      </Modal>
      <Backdrop onClick={onCloseModal} />
    </>
  );
};

export default WifiModal;
