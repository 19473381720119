import { useEffect, useState } from 'react';

const useReloadApp = () => {
  const [shouldReload, setShouldReload] = useState(false);

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  // trigger a site reload, name it 'triggerReload()'
  return () => {
    setShouldReload(true);
  };
};

export default useReloadApp;
