import styled from 'styled-components';

import { color } from '../../styles';

export const Brand = styled.div<{ variant: string }>`
  font-weight: 600;
  font-size: 2.5em;
  color: ${({ variant }) => (variant === 'error' ? color.busy : color.white)};
  margin-bottom: 3em;
`;
export const Name = styled.span`
  font-weight: 300;
  font-size: 1em;
  color: ${color.white};
`;
