import { useSwiper } from 'swiper/react';

import useOrientation from '../../hooks/useOrientation';
import { ReactComponent as Arrow } from '../../img/ArrowButton.svg';
import { StyledButton } from './FloorNavigationButton.styles';

type Props = {
  floorBoundaries: { isFirstFloor: boolean; isLastFloor: boolean };
  variant: 'next' | 'previous';
};

const FloorNavigationButton = ({ floorBoundaries, variant }: Props) => {
  const { isFirstFloor, isLastFloor } = floorBoundaries;
  const { isPortrait } = useOrientation();
  const swiper = useSwiper();

  const renderStyledButton = (
    orientation: 'down' | 'up' | 'left' | 'right',
    condition: boolean,
    action: () => void,
  ) => (
    <StyledButton
      variant={orientation}
      style={{ display: condition ? 'none' : '' }}
      onClick={action}
    >
      <Arrow />
    </StyledButton>
  );

  if (variant === 'next') {
    return renderStyledButton(
      isPortrait ? 'down' : 'left',
      isPortrait ? isLastFloor : isFirstFloor,
      () => (isPortrait ? swiper.slideNext() : swiper.slidePrev()),
    );
  } else if (variant === 'previous') {
    return renderStyledButton(
      isPortrait ? 'up' : 'right',
      isPortrait ? isFirstFloor : isLastFloor,
      () => (isPortrait ? swiper.slidePrev() : swiper.slideNext()),
    );
  }

  return null;
};

export default FloorNavigationButton;
