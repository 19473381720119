import '../text-styles.css';
import './header.css';

import { ReactComponent as Arrow } from '../../img/ArrowBackButton.svg';
import { OccupationIndicator } from '../OccupationIndicator';
import { StyledBackButton } from './Header.styles';
import { PageHeader } from './PageHeader';

export type Props = {
  isRoomOccupiedNow: boolean;
  onClick: () => void;
  onWifiClick: () => void;
  roomName: string;
};

export const RoomHeader = ({
  isRoomOccupiedNow,
  roomName,
  onClick,
  onWifiClick,
}: Props) => {
  return (
    <PageHeader onWifiClick={onWifiClick}>
      <div className="wrapper-left">
        <nav onClick={onClick}>
          <StyledBackButton className="arrow">
            <Arrow />
          </StyledBackButton>
        </nav>
        <h1 className="headline headline-1">{roomName}</h1>
        {isRoomOccupiedNow && <OccupationIndicator />}
      </div>
    </PageHeader>
  );
};
