import { useEffect, useRef } from 'react';

export default function useUpdate() {
  const lastModified = useRef<string | null>(null);
  useEffect(() => {
    const checkForUpdates = () => {
      fetch('/', {
        method: 'HEAD',
      }).then((res) => {
        const data = res.headers.get('last-modified');
        if (lastModified.current) {
          if (lastModified.current !== data) {
            window.location.reload();
          }
        } else {
          lastModified.current = data;
        }
      });
    };
    const interval = setInterval(checkForUpdates, 1000 * 60 * 30);
    checkForUpdates();
    return () => clearInterval(interval);
  }, []);
}
