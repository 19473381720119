/**
 * @description Decodes base64 string into UTF8
 * @param  {string} str
 * @returns UTF8 encoded string
 */
export const b64Decode = (str: string) => {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );
};

/**
 * @description Replaces any whitespace and special character in a string.
 * @param  {string} name
 * @returns Escapes string.
 */
export const socketConformName = (name: string): string => {
  return name.replace(/[^A-Z0-9]+/gi, '_');
};

/**
 * Get the ordinal suffix for a given number.
 *
 * @param {number} i - The number for which to determine the ordinal suffix.
 * @returns {string} The ordinal suffix (e.g., 'st', 'nd', 'rd', or 'th').
 */
export function ordinalSuffix(i: number): string {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
}
