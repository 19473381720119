import { useQuery } from '@apollo/client';
import { useEffect, useRef } from 'react';

import config from '../config';
import type { CheckAuth } from '../generated/CheckAuth';
import type { InitQuery } from '../generated/InitQuery';
import type { RoomsQuery } from '../generated/RoomsQuery';
import { CHECK_AUTH_MOCK, ROOMS_QUERY_MOCK } from '../mocks/graphQLMocks';
import Room from '../models/Room';
import Timer from '../models/Timer';
import { CHECK_AUTH } from '../queries/checkAuth';
import { INIT_QUERY, ROOMS_QUERY } from '../queries/roomData';
import useSockets from './useSockets';
import { useStore } from './useStore';

export const useInit = () => {
  const isDevMode = useStore((state) => state.isDevMode);
  const setInitData = useStore((state) => state.setInitData);
  const setRooms = useStore((state) => state.setRooms);
  const rooms = useStore((state) => state.rooms);
  const setFetchInitData = useStore((state) => state.setFetchInitData);
  const setFetchRooms = useStore((state) => state.setFetchRooms);
  const loadDeviceData = useStore((state) => state.loadDeviceData);
  const fetchRooms = useStore((state) => state.fetchRooms);
  const fetchInitData = useStore((state) => state.fetchInitData);
  const publicSettingsName = useStore((state) => state.publicSettingsName);
  const deviceId = useStore((state) => state.deviceId);
  const timer = useRef(new Timer());
  const authResponseFromQuery = useQuery<CheckAuth>(CHECK_AUTH);
  const authResponse = isDevMode ? CHECK_AUTH_MOCK : authResponseFromQuery.data;
  const authorized = authResponse?.authStatus?.status === 'true';

  const initQuery = useQuery<InitQuery>(INIT_QUERY, {
    skip: !authorized,
  });

  // primaryId is the id of the primary google calendar
  const primaryId = initQuery.data?.primaryId ?? null;

  const roomsQuery = useQuery<RoomsQuery>(ROOMS_QUERY, {
    fetchPolicy: 'network-only',
    skip: !primaryId,
    pollInterval: config.FETCH_INTERVAL,
  });

  useEffect(() => {
    if (!initQuery.data && !isDevMode) return;
    setInitData(initQuery.data, initQuery.loading, initQuery.error);
    setFetchInitData(initQuery.refetch);
  }, [
    initQuery.data,
    initQuery.error,
    initQuery.loading,
    initQuery.refetch,
    isDevMode,
    setFetchInitData,
    setInitData,
  ]);

  useEffect(() => {
    if (!roomsQuery.data && !isDevMode) return;
    if (isDevMode) {
      console.warn('Developer Mode: rooms loaded from mock');
      setRooms(
        ROOMS_QUERY_MOCK.rooms?.map(
          (room) => new Room(timer.current, room, primaryId),
        ) ?? [],
      );
    } else if (roomsQuery.data) {
      setRooms(
        roomsQuery.data.rooms?.map(
          (room) => new Room(timer.current, room, primaryId),
        ) ?? [],
      );
    }
    setFetchRooms(roomsQuery.refetch);
  }, [
    roomsQuery.data,
    primaryId,
    isDevMode,
    roomsQuery.refetch,
    setFetchRooms,
    setRooms,
  ]);

  useEffect(() => {
    if (rooms.length === 0) return;
    loadDeviceData();
  }, [loadDeviceData, rooms]);

  useSockets(fetchRooms, fetchInitData, publicSettingsName, deviceId);
};
