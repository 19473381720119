export const color = {
  background: '#18181a',
  free: '#48ac98',
  busy: '#eb5e4c',
  yellow: '#ffd862',
  red: '#ff0000',
  niceBlue: '#19c5ff',
  white: '#ffffff',
  lightGrey: '#bcbcbc7f',
  mediumGrey: '#424547',
  darkGrey: '#25282b',
  sand: '#cda35f',

  // Jakala Colors
  jBlue: '#040066',
  blue072C: '#040AB2',
  blue285C: '#1941FA',
  blue2925C: '#3C82FF',
  jRed: '#F00A0A',
  red1785C: '#FF3F3F',
  red184C: '#F16A6A',
  red197C: '#FF8982',
};
