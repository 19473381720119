import { useEffect } from 'react';

import { PENDING } from '../bookingController';
import type { Meeting } from '../types';
import { formatMeetingsFromRooms } from '../utils/roomData';
import { useStore } from './useStore';

export function useRoomInStorageLoader() {
  const rooms = useStore((state) => state.rooms);
  const pendingDeletionIds = useStore((state) => state.pendingDeletionIds);
  const roomTitles = useStore((state) => state.roomTitles);

  useEffect(() => {
    if (rooms.length === 0) return;
    // format fetched meetings from rooms
    const meetingsOfCurrentDay = formatMeetingsFromRooms(rooms);

    const meetingsOfCurrentDayPastNow = meetingsOfCurrentDay.filter(
      (meeting) => meeting.endTime > Date.now(),
    );

    roomTitles.map((title: string) => {
      const meetingsInRoomByFetchData = meetingsOfCurrentDayPastNow.filter(
        (meeting) => meeting.room === title,
      );
      // get current meetings from local storage, and filter only these with id: #pending
      const currentPendingMeetings: Meeting[] = JSON.parse(
        localStorage.getItem(`${title}`) ?? '[]',
      ).filter((meeting: Meeting) => meeting.id.includes(PENDING));

      const meetingsInRoomWithStorageAndFetchData: Meeting[] =
        meetingsInRoomByFetchData.concat(currentPendingMeetings);

      // Create a set to keep track of unique meeting identifiers
      const uniqueMeetingIds = new Set<string>();
      // Filter out duplicates based on startTime and endTime
      const meetingsInRoom: Meeting[] =
        meetingsInRoomWithStorageAndFetchData.filter((meeting: Meeting) => {
          // Generate a unique identifier for each meeting based on startTime and endTime
          const identifier = `${meeting.startTime}-${meeting.endTime}`;
          // If the meeting id contains "#pending" and it's a duplicate, return false to filter it out
          if (
            meeting.id.includes(PENDING) &&
            uniqueMeetingIds.has(identifier)
          ) {
            return false;
          }
          // Otherwise, add the identifier to the set and return true to keep the meeting
          uniqueMeetingIds.add(identifier);
          return true;
        });

      // remove meetings which are currently pending for deletion
      const meetingsInRoomWithoutPendingDeletionIds = meetingsInRoom.filter(
        (meeting) => !pendingDeletionIds.includes(meeting.id),
      );

      if (meetingsInRoomWithoutPendingDeletionIds.length !== 0) {
        localStorage.setItem(
          `${title}`,
          JSON.stringify(meetingsInRoomWithoutPendingDeletionIds),
        );
      } else {
        localStorage.removeItem(`${title}`);
      }
    });
  }, [pendingDeletionIds, roomTitles, rooms]);
}
