import { gql } from '@apollo/client';

export const INIT_QUERY = gql`
  query InitQuery {
    floors {
      id
      floorNumber
      map {
        horizontal
        portrait
      }
    }
    primaryId
    deviceId
    defaultPosition
    publicSettings {
      name
    }
  }
`;

export const ROOMS_QUERY = gql`
  query RoomsQuery {
    rooms {
      calendarId
      name
      items {
        id
        owner
        attendees {
          responseStatus
          email
          displayName
        }
        creator {
          email
          displayName
        }
        organizer {
          email
          displayName
        }
        summary
        start {
          dateTime
        }
        end {
          dateTime
        }
        anyoneCanAddSelf
      }
    }
  }
`;

export const BOOK_MUTATION = gql`
  mutation BookMutation(
    $start: String!
    $end: String!
    $attendees: String!
    $summary: String!
  ) {
    bookRoom(
      start: $start
      end: $end
      attendees: $attendees
      summary: $summary
    ) {
      id
    }
  }
`;

export const CANCEL_MUTATION = gql`
  mutation CancelMutation($eventId: String!) {
    cancelRoom(eventId: $eventId)
  }
`;
