import React from 'react';
import styled from 'styled-components';

import { ReactComponent as WifiSVG } from '../../img/Wifi.svg';
import { Clock } from '../Clock';

const StyledHeader = styled.header`
  position: relative;
  z-index: 100;
`;

const StyledWifiButton = styled.button`
  width: 80px;
  height: 80px;
  position: absolute;
  right: 7rem;
  background: none;
  border: none;
  cursor: pointer;
`;

type Props = {
  children: React.ReactNode;
  onWifiClick?: () => void;
};

export const PageHeader = ({ children, onWifiClick }: Props) => {
  return (
    <StyledHeader className="wrapper headline-1">
      {children}
      {onWifiClick && (
        <StyledWifiButton onClick={onWifiClick}>
          <WifiSVG />
        </StyledWifiButton>
      )}
      <Clock />
    </StyledHeader>
  );
};
