import '../text-styles.css';
import './availability.css';

type Props = {
  minutes: number;
};

const Availability = ({ minutes }: Props) => {
  return (
    <label className="subline-1 availability-text">{`Available for ${minutes} minutes`}</label>
  );
};

export default Availability;
