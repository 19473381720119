import { useEffect, useState } from 'react';

export const useCurrentTime = (interval = 500) => {
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  useEffect(() => {
    const timer = setInterval(
      () => setCurrentTime(new Date().getTime()),
      interval,
    );
    return () => clearInterval(timer);
  }, [interval]);

  return currentTime;
};
