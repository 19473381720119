import type { QueryResult } from '@apollo/client';

import type { PublicName } from '../../generated/PublicName';
import { Brand, Name } from './BrandHeader.styles';

type Props = {
  publicData: QueryResult<PublicName>;
};

const BrandHeader = ({ publicData }: Props) => {
  const isPublicNameAvailable = !!publicData.data?.publicSettings?.name;

  return (
    <Brand variant={isPublicNameAvailable ? 'default' : 'error'}>
      {publicData.data?.publicSettings?.name ??
        'Error: organisation not found '}
      <Name>Rooms</Name>
    </Brand>
  );
};

export default BrandHeader;
